import React, { useEffect, useRef } from "react";
import MessageComponent from "./Message";
import { ChatBubbleOutline as ChatIcon } from "@mui/icons-material";

import { ROLE, TextDirection } from "../../../utils/constants";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

const RenderConversations = ({ messages, documents, isFetchingConversation }) => {
  const lastElementRef = useRef(null);
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;

  useEffect(() => {
    if (lastElementRef.current) {
      lastElementRef.current.scrollIntoView();
    }
  }, [messages?.length]);

  const showLoading = messages
    ? messages[messages?.length - 1]?.role === ROLE.USER
    : messages;

  return (
    <div
      className="box-border flex h-[60vh] w-full flex-col justify-start font-nunito text-sm text-[#2B3175]"
      style={{ direction: currentLang === "ar" ? "rtl" : "ltr" }}
    >
      {messages?.map((message, index) => (
        <MessageComponent
          key={message.id}
          message={message}
          index={index}
          messagesLength={messages?.length}
          documents={documents}
          showLoading={showLoading}
        />
      ))}
      {messages?.length === 0 && (
        <div className="flex h-full items-center justify-center">
          <div className="flex w-full flex-col items-center justify-center">
            <div>
              <ChatIcon size={60} color={"#DE3336"} />
            </div>
            <div
              className="mb-2 w-3/4 text-center text-lg font-bold text-black"
              style={{
                direction: currentLang === "ar" ? "rtl" : "ltr",
              }}
            >
              {t("conversation_slogan")}
            </div>

            {isFetchingConversation && (
              <div className="flex h-full items-center justify-center mt-2">
                <CircularProgress />
              </div>
            )}
          </div>
        </div>
      )}
      <div ref={lastElementRef}></div>
    </div>
  );
};

export default RenderConversations;
