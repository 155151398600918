import React from "react";
import SubProcessDisplay from "./SubProcessDisplay";
// import useConversationsStore from "../context/conversationsStore"; // Adjust the import paths as needed
// import { TextDirection } from "../types/conversation"; // Adjust the import paths as needed
// import { formatDisplayDate } from "../utils/timezone"; // Adjust the import paths as needed
import { TextDirection } from "../../../utils/constants";
import { formatDisplayDate } from "../../../utils/timezone";
import useConversationsStore from "./useConversationsStore";

const UserDisplay = ({ message, showLoading }) => {
  const { currentLanguage } = useConversationsStore();
  // console.log('message_2', message)

  // console.log("message", message.content);

  // Convert both \n and <br/> to React <br /> elements
  const formattedContent = message.content
    .replace(/<br\s*\/?>/gi, "\n") // Normalize <br/> tags to \n
    .split("\n") // Split by newline character
    .map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));

  return (
    <>
      <div className="flex border-r bg-gray-00 pb-4 mr-3">
        <div
          className={`mt-4 w-1/12 font-nunito text-gray-60 flex justify-center items-center
    ${
      ""
      // currentLanguage?.textDirection === TextDirection.RightToLeft
      //   ? "order-2"
      //   : ""
    }
  `}
        >
          <div
            className="w-28 text-center"
            style={
              {
                // direction:
                //   currentLanguage?.textDirection === TextDirection.RightToLeft
                //     ? "rtl"
                //     : "ltr",
              }
            }
          >
            {formatDisplayDate(message.created_at)}
          </div>
        </div>

        <div
          className={`mt-4  ${
            // currentLanguage?.textDirection === TextDirection.RightToLeft
            //   ? "pl-3 text-right"
            //   :
            " ml-[-4px]"
          }  font-nunito font-bold text-gray-90`}
        >
          {formattedContent}
        </div>
      </div>

      {message.sources?.length > 0 && (
        <div className="mt-4 mx-8 p-4 rounded-lg shadow-sm bg-white border border-gray-200">
          <h3 className="text-xs font-semibold text-gray-700 mb-3">
            Documents attachés
          </h3>
          {message.sources.map((source, index) => (
            <div
              key={index}
              className="flex items-center justify-between bg-gray-50 p-3 rounded-md shadow-sm mb-2"
            >
              {/* File Info */}
              <div className="text-sm">
                <p className="font-medium text-gray-800 truncate">
                  {source.metadata.original_file_name}
                </p>
                <p className="text-gray-500 text-xs">
                  {source.metadata.language || "N/A"} •{" "}
                  {source.metadata.doc_type || "Unknown"}
                </p>
              </div>

              {/* View Document Link */}
              {/* <a
                href={source.metadata.url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-xs text-blue-500 font-medium hover:underline"
              >
                View
              </a> */}
            </div>
          ))}
        </div>
      )}

      {showLoading && (
        <div className="flex border-b-2 pb-4">
          <div className="flex pl-11 w-max">
            <SubProcessDisplay
              key={`${message.id}-loading-sub-process`}
              messageId={message.id}
              subProcesses={[]}
              isOpen={true}
              toggleOpen={() => null}
              showSpinner={true}
              documents={[]}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default UserDisplay;
