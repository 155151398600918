import React from 'react';
import { LinearProgress, Typography, Box, CircularProgress } from '@mui/material';

// TranslationProgress component
const TranslationProgress = ({
  currentProgress,
  selectedLanguage,
  selectedCitationUrl,
  onTranslationComplete
}) => {
  return (
    <Box sx={{ 
      width: '200px',
      backgroundColor: 'white',
      padding: 2,
      borderRadius: 2,
      // boxShadow: '0 2px 2px rgba(0,0,0,0.1)'
    }}>
      <LinearProgress 
        variant="determinate"
        value={currentProgress}
        sx={{
          height: 6,
          borderRadius: 3,
          backgroundColor: 'rgba(0,0,0,0.1)',
          '& .MuiLinearProgress-bar': {
            borderRadius: 3,
          }
        }}
      />
      <Typography 
        variant="body2"
        color="text.secondary"
        sx={{ mt: 1, textAlign: 'center' }}
      >
        {Math.round(currentProgress)}%
      </Typography>
    </Box>
  );
};

export default TranslationProgress;