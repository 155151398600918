import React, { useEffect } from "react";
import { Box, Grid, Paper, Modal } from "@mui/material";
import { colors } from "../../utils/colors";
import { styles } from "../../utils/styles";
import { PdfFocusProvider } from "../../components/pdf-viewer/pdfContext";
import { useParams } from "react-router-dom";
import HtmlViewer from "../../components/html-viewer/HtmlViewer";
import Chat from "./components/Chat";
import useConversationsStore from "./components/useConversationsStore";
import useDocumentStore from "./components/useDocumentStore"; // Import useDocumentStore
import { isMobile } from "../../utils/utils";
import { useDispatch } from "react-redux";
import { setOpenNavBar } from "../../redux/actions/navbarAction";
import { useTranslation } from "react-i18next";

const ChatScreen = () => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const dispatch = useDispatch();
  const { conversationId } = useParams();
  const is_show_pdf = true;

  const { clearConversations, clearMessages } = useConversationsStore();
  const { isModalOpen, setIsModalOpen, clear } = useDocumentStore(); // Use store functions and state

  useEffect(() => {
    clearConversations();
    clearMessages();

    // Don't open the modal on initial render
  }, [clearConversations, clearMessages]);

  // Handle modal close
  const handleCloseModal = () => {
    setIsModalOpen(false);
    clear(); // Clear selected citation when modal is closed
  };

  useEffect(() => {
    dispatch(setOpenNavBar(false));
  }, [dispatch]);

  return (
    <PdfFocusProvider>
      <Box
        sx={{
          backgroundColor: colors.bg_color,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        dir={currentLang === "ar" ? "rtl" : "ltr"} // Set direction for RTL if Arabic
      >
        <Paper
          elevation={3}
          sx={{
            width: "100%",
            borderRadius: styles.main_container_radius,
            overflow: "hidden",
          }}
        >
          <Grid container sx={{ height: "94vh" }}>
            {/* Chat Section */}
            <Grid
              item
              xs={12}
              md={is_show_pdf ? 6 : 12}
              sx={{
                height: "100%",
                borderRight:
                  is_show_pdf && currentLang !== "ar" ? "1px solid #e0e0e0" : "none", // Adjust border for RTL
                borderLeft:
                  is_show_pdf && currentLang === "ar" ? "1px solid #e0e0e0" : "none", // Add border-left for Arabic
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  padding: "16px",
                }}
                aria-label={t("chat_section")} // Accessible label for chat section
              >
                <Chat
                  conversationId={conversationId}
                  is_show_pdf={is_show_pdf}
                />
              </Box>
            </Grid>

            {/* PDF Viewer Section for Desktop */}
            {!isMobile() && is_show_pdf && (
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                aria-label={t("pdf_viewer_section")} // Accessible label for PDF viewer section
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    overflow: "hidden",
                  }}
                >
                  <HtmlViewer />
                </Box>
              </Grid>
            )}
          </Grid>
        </Paper>

        {/* Modal for Mobile View */}
        <Modal
          open={isModalOpen}
          onClose={handleCloseModal}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          aria-label={t("pdf_viewer_section")} // Accessible label for modal
        >
          <Box
            sx={{
              width: "90%",
              height: "80vh",
              backgroundColor: "white",
              padding: "16px",
              borderRadius: styles.main_container_radius,
              overflow: "auto",
            }}
          >
            <HtmlViewer />
          </Box>
        </Modal>
      </Box>
    </PdfFocusProvider>
  );
};

export default ChatScreen;
