import { OPEN_NAVBAR, SET_OPEN_NAVBAR } from "./types";

// Action Creators
export const openNavBar = () => {
    return {
        type: OPEN_NAVBAR,
    };
};

export const setOpenNavBar = (isOpen) => {
    return {
        type: SET_OPEN_NAVBAR,
        payload: isOpen,
    };
};
