import React, { useEffect, useState, useRef } from "react";
import {
  CircularProgress,
  Box,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import useDocumentStore from "../../screens/ChatScreen/components/useDocumentStore";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { useSnackbar } from "notistack";
import html2pdf from "html2pdf.js";
import { backendClient } from "../../api/backend";
import { jsPDF } from "jspdf";
import Fuse from "fuse.js"; // Import Fuse.js for fuzzy search
import { useTranslation } from "react-i18next";
import LanguageSelector from "./components/LanguageSelector";
import TranslationProgress from "./components/TranslationProgress";
import { backendUrl } from "../../config";
import { useSelector } from "react-redux";

// Custom color configuration for highlighting
const HIGHLIGHT_CLASSNAME = "opacity-40 saturate-[3] highlighted-by-llama ";
const highlightColor = "background-color: yellow;"; // Define the highlight color

// Helper function to split HTML content into sentences while preserving the structure
const splitHtmlIntoSentences = (htmlContent) => {
  // Use regex to split by punctuation without breaking HTML tags
  return htmlContent.match(/[^.!?]+[.!?]+[\])'"`’”]*|.+$/g);
};

// Function to highlight the matching sentence within the HTML content
const multiHighlightHtml = (htmlContent, searchText) => {
  const sentences = splitHtmlIntoSentences(htmlContent);
  if (!searchText || searchText.trim() === "") return htmlContent;

  const fuse = new Fuse(sentences, {
    includeScore: true,
    threshold: 0.3,
    keys: ["text"], // Search within the text content
  });

  const result = fuse.search(searchText);
  if (result.length > 0) {
    const searchResult = result[0].item;
    const highlightedSentence = `<span style="${highlightColor}">${searchResult}</span>`;
    return htmlContent.replace(searchResult, highlightedSentence);
  }

  return htmlContent;
};

export default function HtmlViewer() {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;

  const { loading, setLoading, selectedCitation } = useDocumentStore();
  const { enqueueSnackbar } = useSnackbar();
  const [htmlContent, setHtmlContent] = useState(null);
  const iframeRef = useRef(null);
  const [selectedCitationUrl, setSelectedCitationUrl] = useState(null);
  const [originalHtmlContent, setOriginalHtmlContent] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(
    selectedCitation?.metadata?.language || "fr"
  );

  // Function to highlight text in HTML content
  const highlightTextInContent = (content, sectionTitle) => {
    if (sectionTitle) {
      return multiHighlightHtml(content, sectionTitle);
    }
    return content;
  };

  // const handleExport = async () => {
  //   setIsDownloading(true);
  //   const filename = selectedCitation?.metadata?.long_title
  //     ? `${selectedCitation?.metadata?.long_title}.pdf`
  //     : "document.pdf";

  //   try {
  //     if (fileType === "pdf") {
  //       // For PDF files, fetch and download the existing PDF
  //       const response = await fetch(htmlContent);
  //       if (!response.ok) {
  //         throw new Error("Failed to fetch PDF content");
  //       }
  //       const blob = await response.blob();
  //       const url = window.URL.createObjectURL(blob);
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.download = filename;
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //       window.URL.revokeObjectURL(url);
  //     } else {
  //       // Access iframe content
  //       const iframeDocument = iframeRef.current?.contentWindow?.document;
  //       if (!iframeDocument) {
  //         throw new Error("Cannot access iframe content");
  //       }

  //       // Clone and clean content
  //       const contentDiv = document.createElement("div");
  //       contentDiv.innerHTML = iframeDocument.body.innerHTML;

  //       // Remove inline images or problematic elements
  //       const images = contentDiv.getElementsByTagName("img");
  //       Array.from(images).forEach((img) => {
  //         if (img.src.startsWith("data:")) {
  //           img.remove();
  //         }
  //       });

  //       // Configure PDF options
  //       const pdf = new jsPDF({
  //         orientation: "p",
  //         unit: "pt",
  //         format: "a4",
  //         putOnlyUsedFonts: true,
  //         floatPrecision: 16,
  //       });

  //       await pdf.html(contentDiv, {
  //         callback: function (doc) {
  //           try {
  //             doc.save(filename);
  //             enqueueSnackbar(t("export_success"), {
  //               variant: "success",
  //             });
  //           } catch (error) {
  //             console.error("Error saving PDF:", error);
  //             throw error;
  //           }
  //         },
  //         margin: [20, 10, 20, 10],
  //         autoPaging: "text",
  //         width: 190,
  //         windowWidth: 900,
  //         html2canvas: {
  //           scale: 0.8,
  //           useCORS: true,
  //           removeContainer: true,
  //           letterRendering: true,
  //           allowTaint: true,
  //           logging: false,
  //           imageTimeout: 0,
  //           onclone: (clonedDoc) => {
  //             // Remove problematic elements in cloned document
  //             const clonedImages = clonedDoc.getElementsByTagName("img");
  //             Array.from(clonedImages).forEach((img) => {
  //               if (img.src.startsWith("data:")) {
  //                 img.remove();
  //               }
  //             });
  //           },
  //         },
  //         image: {
  //           type: "jpeg",
  //           quality: 0.95,
  //         },
  //       });
  //     }
  //   } catch (error) {
  //     console.error("Error exporting document:", error);
  //     enqueueSnackbar(t("export_error"), {
  //       variant: "error",
  //     });
  //   } finally {
  //     setIsDownloading(false);
  //   }
  // };

  const handleExport = async () => {
    setIsDownloading(true);
    const filename = selectedCitation?.metadata?.long_title
      ? `${selectedCitation?.metadata?.long_title}.pdf`
      : "document.pdf";

    try {
      let content;
      if (fileType === "pdf") {
        content = htmlContent;
      } else {
        // Access iframe content
        const iframeDocument = iframeRef.current?.contentWindow?.document;
        if (!iframeDocument) {
          throw new Error("Cannot access iframe content");
        }

        // Clone and clean content
        const contentDiv = document.createElement("div");
        contentDiv.innerHTML = iframeDocument.body.innerHTML;

        // Remove inline images or problematic elements
        const images = contentDiv.getElementsByTagName("img");
        Array.from(images).forEach((img) => {
          if (img.src.startsWith("data:")) {
            img.remove();
          }
        });

        content = contentDiv.innerHTML;
      }

      const blob = await backendClient.exportDocument(
        content,
        fileType,
        filename
      );

      // Download the blob
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      enqueueSnackbar(t("export_success"), {
        variant: "success",
      });
    } catch (error) {
      console.error("Error exporting document:", error);
      if (error.message === "unauthorized_access") {
        enqueueSnackbar(t("unauthorized_access"), { variant: "error" });
      } else if (error.message === "session_expired") {
        enqueueSnackbar(t("session_expired"), { variant: "error" });
      } else {
        enqueueSnackbar(t("export_error"), { variant: "error" });
      }
    } finally {
      setIsDownloading(false);
    }
  };

  const scrollToHighlightedElement = () => {
    const iframeDocument = iframeRef.current?.contentWindow?.document;
    const highlightedElement = iframeDocument?.getElementById(
      "highlighted-section"
    );
    if (highlightedElement) {
      highlightedElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };
  // const handleLanguageChange = async (value) => {
  //   setLoading(true);
  //   const newLanguage = value;
  //   setSelectedLanguage(newLanguage);

  //   console.log("newLanguageXX", newLanguage);
  //   console.log("selectedCitationUrl", selectedCitationUrl);

  //   const results = await backendClient.translateHtml(
  //     selectedCitationUrl,
  //     newLanguage
  //   );
  //   console.log("resultsXX", results);

  //   setSelectedCitationUrl(results.translated_url);
  //   setLoading(false);

  //   // i18n.changeLanguage(newLanguage);
  // };

  const [translationProgress, setTranslationProgress] = useState(0);
  const [isTranslationInProgress, setIsTranslationInProgress] = useState(false);

  const tokenPayload = useSelector((state) => state.auth.user.token);

  const handleLanguageChange = async (value) => {
    setIsTranslationInProgress(true);
    setLoading(true);
    const newLanguage = value;
    setSelectedLanguage(newLanguage);
    setTranslationProgress(0);

    // Check if selected language matches document language
    const documentLanguage = selectedCitation?.metadata?.language;
    console.log("documentLanguage", documentLanguage);
    console.log("newLanguage", newLanguage);
    const isOriginalLanguage =
      documentLanguage && newLanguage === documentLanguage;
    if (isOriginalLanguage) {
      // Get original document URL
      const containerName = process.env.REACT_APP_AZURE_CONTAINER_NAME;
      const blobName =
        selectedCitation?.metadata?.file_name || selectedCitation?.file_name;

      try {
        const data = await backendClient.getDocumentSas(
          containerName,
          blobName
        );
        setSelectedCitationUrl(data.blob_url_with_sas);
        setLoading(false);
        setIsTranslationInProgress(false);
        setTranslationProgress(100);
        enqueueSnackbar(t("original_document_sucess"), { variant: "success" });
        return;
      } catch (error) {
        console.error("Error getting original document:", error);
      }
    }

    try {
      const response = await fetch(backendUrl + "api/document/translate-html", {
        method: "POST",
        headers: {
          Authorization: `${tokenPayload.token_type
            .charAt(0)
            .toUpperCase()}${tokenPayload.token_type.slice(1)} ${
            tokenPayload.access_token
          }`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          file_url: selectedCitationOriginalUrl,
          target_language: newLanguage,
        }),
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let buffer = "";

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        buffer += decoder.decode(value, { stream: true });
        const lines = buffer.split("\n\n");

        for (let i = 0; i < lines.length - 1; i++) {
          const message = lines[i];
          if (!message.trim() || !message.startsWith("data: ")) continue;

          try {
            const data = JSON.parse(message.replace("data: ", ""));
            console.log("Progress data:", data);

            if (data.error) {
              console.error("Translation error:", data.error);
              enqueueSnackbar(t("translation_error"), { variant: "error" });
              setLoading(false);
              setIsTranslationInProgress(false);
              setTranslationProgress(0);
              return;
            }

            if (data.completed && data.translated_url) {
              console.log("Translation completed");
              setSelectedCitationUrl(data.translated_url);
              setLoading(false);
              setTranslationProgress(100);
              setIsTranslationInProgress(false);
              // if (!isOriginalLanguage) {
              enqueueSnackbar(t("translated_success"), {
                variant: "success",
              });
              // }
              return;
            }

            if (data.progress !== undefined) {
              const progressValue = Number(data.progress);
              setTranslationProgress(progressValue);
              console.log(
                `Translation progress: ${progressValue}% (${data.completed}/${data.total} batches)`
              );
            }
          } catch (e) {
            console.error("Error parsing message:", e, message);
          }
        }

        buffer = lines[lines.length - 1];
      }
    } catch (error) {
      console.error("Translation error:", error);
      enqueueSnackbar(t("translation_error"), { variant: "error" });
      setIsTranslationInProgress(false);
      setLoading(false);
      setTranslationProgress(0);
    }
  };

  const [fileType, setFileType] = useState(null); // Add this with other state declarations
  const [selectedCitationOriginalUrl, setSelectedCitationOriginalUrl] =
    useState(null); // Add this with other state declarations

  useEffect(() => {
    const fetchAndHighlightContent = async () => {
      const containerName = process.env.REACT_APP_AZURE_CONTAINER_NAME;
      const blobName =
        selectedCitation?.metadata?.file_name || selectedCitation?.file_name;

      try {
        setLoading(true);
        const data = await backendClient.getDocumentSas(
          containerName,
          blobName
        );
        const response = await fetch(data.blob_url_with_sas);
        setSelectedCitationUrl(response.url);
        setSelectedCitationOriginalUrl(response.url);

        // Determine file type from the filename
        const isPdf = blobName.toLowerCase().endsWith(".pdf");
        
        setFileType(isPdf ? "pdf" : "html");

        if (isPdf) {
          // For PDF files, we'll just store the URL
          setHtmlContent(response.url);
        } else {
          // For HTML files, continue with existing logic
          let content = await response.text();
          setOriginalHtmlContent(content);
          setHtmlContent(content);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching or processing the document:", error);
        enqueueSnackbar(t("document_fetch_error"), {
          variant: "error",
        });
      }
    };

    if (selectedCitation?.metadata?.file_name || selectedCitation?.file_name) {
      fetchAndHighlightContent();
    } else {
      setHtmlContent(null);
    }
  }, [selectedCitation]);

  // Second useEffect for handling content updates from URL
  useEffect(() => {
    const fetchUrlContent = async () => {
      if (!selectedCitationUrl || fileType === "pdf") {
        return;
      }

      try {
        setLoading(true);
        const response = await fetch(selectedCitationUrl);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const content = await response.text();

        setOriginalHtmlContent(content);
        setHtmlContent(content);
        // enqueueSnackbar(t("translated_success"), { variant: "success" });
      } catch (error) {
        console.error("Error fetching content from URL:", error);
        enqueueSnackbar(t("citation_url_fetch_error"), { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (selectedCitationUrl) {
      fetchUrlContent();
    }
  }, [selectedCitationUrl]);

  return (
    <Box
      dir={currentLang === "ar" ? "rtl" : "ltr"} // Dynamically set direction
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        padding: "8px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          // justifyContent: "flex-end",
          alignItems: "center",
          paddingBottom: "8px",
        }}
      >
        {/* <FormControl sx={{ minWidth: 200 }}>
          <InputLabel id="language-select-label">{t("language")}</InputLabel>
          <Select
            labelId="language-select-label"
            value={selectedLanguage}
            onChange={handleLanguageChange}
          >
            <MenuItem value="en">English</MenuItem>
            <MenuItem value="ar">Arabic</MenuItem>
            <MenuItem value="fr">French</MenuItem>
            <MenuItem value="es">Spanish</MenuItem>
            <MenuItem value="de">German</MenuItem>
            <MenuItem value="zh">Chinese</MenuItem>
          </Select>
        </FormControl> */}

        <LanguageSelector
          disabled={!htmlContent}
          currentLang={selectedLanguage}
          onLanguageChange={handleLanguageChange}
        />

        {htmlContent && !isTranslationInProgress && (
          <IconButton
            onClick={handleExport}
            aria-label="export"
            disabled={isDownloading}
          >
            {isDownloading ? <CircularProgress size={24} /> : <GetAppIcon />}
          </IconButton>
        )}
      </Box>

      {loading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            zIndex: 10,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 4,
            }}
          >
            <CircularProgress size={50} />
            {isTranslationInProgress && (
              <TranslationProgress
                currentProgress={translationProgress}
                selectedLanguage={selectedLanguage}
                selectedCitationUrl={selectedCitationUrl}
                onTranslationComplete={(translatedUrl) => {
                  setSelectedCitationUrl(translatedUrl);
                  setLoading(false);
                }}
              />
            )}
          </Box>
        </Box>
      ) : (
        <>
          {htmlContent ? (
            fileType === "pdf" ? (
              <iframe
                src={htmlContent}
                // title="PDF document"
                width="100%"
                height="100%"
                style={{
                  border: "none",
                  display: loading ? "none" : "block",
                }}
              />
            ) : (
              <iframe
                ref={iframeRef}
                // title="HTML document"
                srcDoc={htmlContent}
                width="100%"
                height="100%"
                sandbox="allow-same-origin allow-scripts allow-popups"
                style={{
                  border: "none",
                  display: loading ? "none" : "block",
                }}
                dir={currentLang === "ar" ? "rtl" : "ltr"}
              />
            )
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                height: "100%",
              }}
              dir={currentLang === "ar" ? "rtl" : "ltr"}
            >
              <Logo
                style={{
                  width: "120px",
                  height: "120px",
                }}
              />
              <Box
                sx={{
                  marginTop: 2,
                  fontSize: "16px",
                  color: "#333",
                  textAlign: "center",
                }}
              >
                {t("choose_document")}
                <br />
                {t("choose_document_details")}
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  );
}
