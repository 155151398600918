import { backendClient } from "../../api/backend";
import { backendUrl } from "../../config";
import {
  CREATE_CONVERSATION_FAILURE,
  CREATE_CONVERSATION_REQUEST,
  CREATE_CONVERSATION_SUCCESS,
  DELETE_CONVERSATION,
  FETCH_CONVERSATION_FAILURE,
  FETCH_CONVERSATION_REQUEST,
  FETCH_CONVERSATION_SUCCESS,
  SEARCH_CONVERSATIONS_FAILURE,
  SEARCH_CONVERSATIONS_REQUEST,
  SEARCH_CONVERSATIONS_SUCCESS,
  SET_CONVERSATION,
  SET_MESSAGES,
  SHARE_CONVERSATION_SUCCESS,
  SYSTEM_SEND_MESSAGE,
  USER_SEND_MESSAGE,
  UPDATE_MESSAGE_LIKE
} from "./types";
import { EventSourcePolyfill } from "event-source-polyfill";
import { v4 as uuidv4 } from "uuid";

export const fetchConversations =
  (user_id, limit = 5, offset = 0) =>
  async (dispatch) => {
    dispatch({ type: "FETCH_CONVERSATIONS_REQUEST" });

    try {
      const data = await backendClient.fetchUserConversations(
        user_id,
        limit,
        offset
      );
      dispatch({
        type: "FETCH_CONVERSATIONS_SUCCESS",
        payload: data,
      });
    } catch (error) {
      console.error(
        `Erreur lors de la récupération des conversations : ${error.message}`
      );
      dispatch({
        type: "FETCH_CONVERSATIONS_FAILURE",
        error: `Échec de la récupération des conversations : ${error.message}`,
      });
    }
  };

export const createConversationRequest = () => ({
  type: CREATE_CONVERSATION_REQUEST,
});

export const createConversationSuccess = (conversation) => ({
  type: CREATE_CONVERSATION_SUCCESS,
  payload: conversation,
});

export const createConversationFailure = (error) => ({
  type: CREATE_CONVERSATION_FAILURE,
  payload: error,
});

export const createConversation = (userId) => {
  return async (dispatch) => {
    dispatch(createConversationRequest()); // Dispatch the request action

    try {
      // const conversation = await backendClient.createConversation(userId); // Call the API
      const conversation = {
        id: uuidv4(),
        users_ids: [userId],
        messages: [],
        conversation_documents: [],
        title: "",
      };

      dispatch(createConversationSuccess(conversation)); // Dispatch success with conversation object
      return conversation;
    } catch (error) {
      // Dispatch failure with error message
      const errorMessage =
        error.message ||
        "Échec de la création de la conversation. Veuillez réessayer.";
      dispatch(createConversationFailure(errorMessage));
    }
  };
};

export const deleteConversations =
  (conversationIds, userId) => async (dispatch) => {
    try {
      console.log("DELETE conversationIds, userId", conversationIds, userId);
      await backendClient.deleteConversations(conversationIds); // Backend call
      dispatch({
        type: DELETE_CONVERSATION,
        payload: conversationIds, // Pass the conversation IDs to be deleted
      });
    } catch (error) {
      console.error("Failed to delete conversations:", error);
    }
  };

export const fetchConversationRequest = () => ({
  type: FETCH_CONVERSATION_REQUEST,
});

export const fetchConversationSuccess = (conversation) => ({
  type: FETCH_CONVERSATION_SUCCESS,
  payload: conversation,
});

export const fetchConversationFailure = (error) => ({
  type: FETCH_CONVERSATION_FAILURE,
  payload: error,
});

export const updateMessageLike = (message_id, value) => {
  return async (dispatch, getState) => {
    try {
      // Call the backend to update the like field
      console.log("updating like", message_id, value);
      await backendClient.updateMessageLike(message_id, value);

      // Dispatch the update to Redux store
      dispatch({
        type: UPDATE_MESSAGE_LIKE,
        payload: { message_id, value },
      });
    } catch (error) {
      console.error("Failed to update message like:", error);
      // Optionally handle errors, e.g., show an error message to the user
    }
  };
};

export const fetchConversation = (conversationId) => async (dispatch) => {
  dispatch(fetchConversationRequest());
  try {
    const result = await backendClient.fetchConversation(conversationId);
    if (result?.messages) {
      dispatch(setMessages(result.messages));
    }
    if (result?.documents) {
      dispatch(setConversation(result.documents));
    }
    dispatch(fetchConversationSuccess(result));
    return result;
  } catch (error) {
    console.error("Error fetching conversation:", error);
    dispatch(fetchConversationFailure(error.message));
  }
};

export const setConversation = (conversation) => ({
  type: SET_CONVERSATION,
  payload: conversation,
});

export const setMessages = (messages) => ({
  type: SET_MESSAGES,
  payload: messages,
});

export const userSendMessage = (content) => ({
  type: USER_SEND_MESSAGE,
  payload: content,
});

export const systemSendMessage = (message) => ({
  type: SYSTEM_SEND_MESSAGE,
  payload: message,
});

// Thunk to submit a new message
export const submitMessage =
  (conversationId, userMessage) => async (dispatch) => {
    const tokenPayload = backendClient.getToken();
    if (!tokenPayload) {
      console.error("No authentication token available.");
      return;
    }

    await dispatch(userSendMessage(userMessage));

    const token = `${tokenPayload.token_type
      .charAt(0)
      .toUpperCase()}${tokenPayload.token_type.slice(1)} ${encodeURIComponent(
      tokenPayload.access_token
    )}`;
    const encodedUserMessage = encodeURIComponent(userMessage);
    const messageEndpoint = `${backendUrl}api/conversation/${conversationId}/message?user_message=${encodedUserMessage}`;

    const events = new EventSourcePolyfill(messageEndpoint, {
      headers: {
        Authorization: token,
      },
    });

    // console.log('eventsXX', events)
    // console.log('messageEndpointXX', messageEndpoint)
    // console.log('tokenXXX', token)

    // events.onmessage = (event) => {
    //   const parsedData = JSON.parse(event.data);
    //   console.log('parsedDataXX', parsedData)
    //   dispatch(systemSendMessage(parsedData));

    //   if (parsedData.status === MESSAGE_STATUS.SUCCESS || parsedData.status === MESSAGE_STATUS.ERROR) {
    //     events.close();
    //   }
    // };

    // events.onerror = (event) => {
    //   console.error("EventSource failed:", event);
    //   events.close();
    // };
  };

// Action creators
export const searchConversationsRequest = () => ({
  type: SEARCH_CONVERSATIONS_REQUEST,
});

export const searchConversationsSuccess = (conversations) => ({
  type: SEARCH_CONVERSATIONS_SUCCESS,
  payload: conversations,
});

export const searchConversationsFailure = (error) => ({
  type: SEARCH_CONVERSATIONS_FAILURE,
  payload: error,
});

// Search Conversations function
export const searchConversations = (userId, searchTerm) => {
  return async (dispatch) => {
    dispatch(searchConversationsRequest());
    try {
      const response = await backendClient.searchUserConversations(
        userId,
        searchTerm
      );
      const data = await response.json();
      dispatch(searchConversationsSuccess(data));
    } catch (error) {
      dispatch(searchConversationsFailure(error.message));
    }
  };
};

export const shareConversation =
  (conversationId, userIds) => async (dispatch) => {
    try {
      const result = await backendClient.shareConversation(
        conversationId,
        userIds
      );
      console.log("result", result);

      if (result.ok) {
        dispatch({ type: SHARE_CONVERSATION_SUCCESS, payload: conversationId });
      }
    } catch (error) {
      console.error(`Failed to share conversation: ${error.message}`);
    }
  };
