export const colors = {
  blue: "#BDC4CB",
  green: "#BEC4CA",
  gray: "#797E90",
  gray_1: "#f3e5f5",
  gray_0: "#F5F5F5",
  gray_3: "#051221",
  white: "#FFFFFF",
  black: "#000000",
  bg_color: "F7F7F8",
};

export const DocumentColorEnum = {
  purple: "llama-purple",
  magenta: "llama-magenta",
  red: "llama-red",
  orange: "llama-orange",
  yellow: "llama-yellow",
  lime: "llama-lime",
  teal: "llama-teal",
  cyan: "llama-cyan",
  blue: "llama-blue",
  indigo: "llama-indigo",
};

// order matters! must be high contrast
export const documentColors = [
  DocumentColorEnum.lime,
  DocumentColorEnum.orange,
  DocumentColorEnum.cyan,
  DocumentColorEnum.yellow,
  DocumentColorEnum.magenta,
  DocumentColorEnum.red,
  DocumentColorEnum.purple,
  DocumentColorEnum.teal,
  DocumentColorEnum.indigo,
  DocumentColorEnum.blue,
];
