import { combineReducers } from "redux";
import authReducer from "./authReducer";
import conversationReducer from "./conversationReducer";
import searchReducer from "./searchReducer";
import navbarReducer from "./navbarReducer";
import userReducer from "./userReducer";
// import messageReducer from "./messageReducer";

export default combineReducers({
  auth: authReducer,
  conversations: conversationReducer,
  search: searchReducer,
  navbar: navbarReducer,
  user: userReducer,
  // messages: messageReducer,
  // other reducers
});
