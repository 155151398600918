import { MESSAGE_STATUS } from "../../utils/constants";
import {
  CREATE_CONVERSATION_FAILURE,
  CREATE_CONVERSATION_REQUEST,
  CREATE_CONVERSATION_SUCCESS,
  DELETE_CONVERSATION,
  FETCH_CONVERSATIONS_FAILURE,
  FETCH_CONVERSATIONS_REQUEST,
  FETCH_CONVERSATIONS_SUCCESS,
  RESET_CONVERSATION,
  SEARCH_CONVERSATIONS_FAILURE,
  SEARCH_CONVERSATIONS_REQUEST,
  SEARCH_CONVERSATIONS_SUCCESS,
  SET_CONVERSATION,
  SET_MESSAGES,
  SYSTEM_SEND_MESSAGE,
  USER_SEND_MESSAGE,
} from "../actions/types";

const initialState = {
  conversations: [],
  messages: [],
  conversation: null,
  loading: false,
  loadingCreate: false,
  error: null,
};

const conversationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONVERSATIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_CONVERSATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        conversations: action.payload,
      };
    case FETCH_CONVERSATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case SEARCH_CONVERSATIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SEARCH_CONVERSATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        conversations: action.payload,
      };
    case SEARCH_CONVERSATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_CONVERSATION_REQUEST:
      return {
        ...state,
        loadingCreate: true,
        error: null, // Clear any previous error
      };

    case CREATE_CONVERSATION_SUCCESS:
      return {
        ...state,
        loadingCreate: false,
        conversations: [...state.conversations, action.payload], // Add new conversation
        error: null, // Clear any previous error
      };

    case CREATE_CONVERSATION_FAILURE:
      return {
        ...state,
        loadingCreate: false,
        error: action.payload, // Set error message
      };

    case DELETE_CONVERSATION:
      return {
        ...state,
        conversations: state.conversations.filter(
          (t) => !action.payload.includes(t.id)
        ),
      };

    case SET_CONVERSATION:
      return {
        ...state,
        conversation: action.payload,
      };

    case SET_MESSAGES:
      return {
        ...state,
        messages: action.payload,
      };
    // case SET_MESSAGES:
    //   return {
    //     ...state,
    //     messages: action.payload.map((message) => ({
    //       ...message,
    //       content: message.content.replace(/\n/g, "<br/>"),
    //     })),
    //   };

    case USER_SEND_MESSAGE:
      console.log("tototxxx", action.payload);
      return {
        ...state,
        messages: [
          ...state.messages,
          {
            id: Math.random().toString(),
            content: action.payload.replace(/\n/g, "<br/>"),
            role: "USER",
            status: MESSAGE_STATUS.PENDING,
            created_at: new Date(),
          },
        ],
      };

    case SYSTEM_SEND_MESSAGE:
      const updatedMessages = state.messages.map((msg) =>
        msg.id === action.payload.id ? { ...msg, ...action.payload } : msg
      );
      return {
        ...state,
        messages: updatedMessages,
      };

      case RESET_CONVERSATION:
        return initialState
        

    default:
      return state;
  }
};

export default conversationReducer;
