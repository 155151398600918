import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Paper,
  Button,
  Avatar,
  Pagination,
  Popover,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat"; // Import the ChatIcon
import ReactGA from "react-ga4";
import { colors } from "../../utils/colors";
import { styles } from "../../utils/styles";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import ShareIcon from "@mui/icons-material/Share"; // Import the Share icon
import { backendClient } from "../../api/backend";
import {
  fetchConversations,
  createConversation,
  deleteConversations,
  searchConversations,
  shareConversation,
} from "../../redux/actions/conversationAction"; // Import your createConversation action
import CustomButton from "../../components/CustomButton";
import { formatDateWithTimezoneOffset } from "../../utils/utils";
import useConversationsStore from "./components/useConversationsStore";
import debounce from "lodash/debounce";
import ShareConversationModal from "./components/ShareConversationModal";
import useDocumentStore from "./components/useDocumentStore";
import { useTranslation } from "react-i18next";

const ChatHistory = () => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth?.user?.user);
  const { conversations, loading, loadingCreate } = useSelector(
    (state) => state.conversations
  );

  const { setSelectedCitation } = useDocumentStore();
  const { clearConversations, clearMessages } = useConversationsStore();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const rowsPerPage = 5;
  const [selected, setSelected] = useState([]); // State to track selected conversations
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [isShareModalOpen, setShareModalOpen] = useState(false);
  const [currentConversationId, setCurrentConversationId] = useState(null);
  const [totalConversations, setTotalConversations] = useState(0);
  const [filteredRows, setFiltredRows] = useState(conversations || []);
  const [totalCount, setTotalCount] = useState(0);

  // users
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const fetchTotalCount_rd = async () => {
    const { totalConversations } =
      await backendClient.getConversationsCountByUser(user?.id);
    setTotalConversations(totalConversations);
  };

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/chat-history",
      title: "Chat History",
    });
  }, []);

  useEffect(() => {
    fetchTotalCount_rd();
  }, []);

  useEffect(() => {
    const totalCount_ =
      searchTerm.length > 0
        ? filteredRows.length.toLocaleString()
        : totalConversations.toLocaleString();
    setTotalCount(totalCount_);
  }, [searchTerm, conversations]);

  useEffect(() => {
    if (user && user.id) {
      dispatch(fetchConversations(user.id));
    }
  }, [user]);

  useEffect(() => {
    setFiltredRows(conversations);
  }, [conversations]);

  // Filter rows based on search term

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce((term) => {
      if (user && user.id) {
        dispatch(searchConversations(user.id, term)); // Dispatch search action
      }
    }, 500), // 2-second delay
    [dispatch, user]
  );

  const handleSearch = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    debouncedSearch(term);
    // Google Analytics event for search
    ReactGA.event({
      category: "Search",
      action: "User searched conversations",
      label: term,
    });
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    const offset = (value - 1) * rowsPerPage;
    dispatch(fetchConversations(user.id, rowsPerPage, offset));
  };

  const fetchConversations_rd = () => {
    const offset = (page - 1) * rowsPerPage;
    dispatch(fetchConversations(user.id, rowsPerPage, offset));
  };

  const handleOpenConversation = async (chatId) => {
    clearConversations();
    clearMessages();
    navigate(`/conversation/${chatId}`);
    enqueueSnackbar("Conversation opened!", { variant: "success" });
  };

  const handleCreateConversation = async () => {
    try {
      const newConversation = await dispatch(createConversation(user.id)); // Dispatch create conversation
      if (newConversation) {
        setSelectedCitation([]);
        enqueueSnackbar("Conversation créée.", {
          variant: "success",
        });
        navigate(`/conversation/${newConversation.id}`); // Navigate to the new conversation

        // Google Analytics event for creating conversation
        ReactGA.event({
          category: "Conversation",
          action: "User created a new conversation",
          label: `User ID: ${user.id}`,
        });
      }
    } catch (error) {
      enqueueSnackbar("Failed to create conversation", { variant: "error" });
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = conversations.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, id) => {
  //   const selectedIndex = selected.indexOf(id);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, id);
  //   } else if (selectedIndex >= 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }

  //   setSelected(newSelected);
  // };

  const handleDeleteConversations = async () => {
    try {
      setLoadingDelete(true);
      await dispatch(deleteConversations(selected, user.id));
      setSelected([]); // Clear selection after deletion
      fetchConversations_rd();
      await fetchTotalCount_rd();
      enqueueSnackbar("Conversations deleted successfully!", {
        variant: "success",
      });

      // Google Analytics event for deleting conversations
      ReactGA.event({
        category: "Conversation",
        action: "User deleted conversations",
        label: `Deleted Conversation IDs: ${selected.join(", ")}`,
      });
    } catch (error) {
      enqueueSnackbar("Failed to delete conversations", { variant: "error" });
    }
    setLoadingDelete(false);
  };

  const handleCheckboxClick = (event, id) => {
    event.stopPropagation(); // Prevent row click when selecting
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex >= 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleShareConversation = (conversationId) => {
    setCurrentConversationId(conversationId); // Set the current conversation ID
    setShareModalOpen(true); // Open the modal for sharing

    // Debugging
    console.log(`Initiating share for conversation ID: ${conversationId}`);

    // Google Analytics Event
    ReactGA.event({
      category: "Conversation",
      action: "User initiated sharing conversation",
      label: `Conversation ID: ${conversationId}`,
    });
  };

  const handleShare = async (selectedUserIds) => {
    if (currentConversationId && selectedUserIds.length > 0) {
      await dispatch(shareConversation(currentConversationId, selectedUserIds))
        .then(() => {
          console.log(
            `Conversation ${currentConversationId} shared successfully!`
          );
        })
        .catch((error) => {
          console.error(`Failed to share conversation: ${error.message}`);
        });
    } else {
      console.error("No user IDs selected for sharing.");
    }
  };

  return (
    <Box
      sx={{ backgroundColor: colors.bg_color }}
      dir={currentLang === "ar" ? "rtl" : "ltr"}
    >
      <Paper
        elevation={3}
        sx={{
          padding: styles.main_container_padding,
          borderRadius: styles.main_container_radius,
        }}
        dir={currentLang === "ar" ? "rtl" : "ltr"}
      >
        <ShareConversationModal
          open={isShareModalOpen}
          onClose={() => setShareModalOpen(false)}
          onShare={handleShare}
          organizationName={user?.organization_name} // Pass organization name to modal
        />
        <Typography
          variant="h6"
          sx={{
            fontWeight: 600,
            marginBottom: "16px",
            textAlign: currentLang === "ar" ? "right" : "left",
          }}
        >
          {t("chat_history")}
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent:
              currentLang === "ar" ? "flex-start" : "space-between", // Adjust based on direction
            alignItems: "center", // Vertically align the elements
            marginBottom: "24px",
          }}
        >
          {/* Search bar */}
          <TextField
            fullWidth
            placeholder={t("search_placeholder_chat")}
            variant="outlined"
            value={searchTerm}
            onChange={handleSearch}
            sx={{ marginRight: currentLang === "ar" ? "" : "16px" }} // Add margin between the search bar and the button
          />

          {selected?.length > 0 && (
            <CustomButton
              text={
                // selected.length <= 1
                //   ? t("delete_single_conversation")
                //   : t("delete_multiple_conversations")
                t("delete_conversation")
              }
              outlined={false} // Filled button
              loading={loadingDelete} // Pass loading state
              onClick={handleDeleteConversations} // Call the create conversation function
              sx={{
                whiteSpace: "nowrap", // Prevents text wrapping inside the button
                // padding: "8px 16px", // Adds padding for better spacing
                minWidth: "auto", // Ensures the button width adjusts to text
                marginRight: "16px",
              }}
            />
          )}

          <CustomButton
            text={t("create_conversation")}
            outlined={false} // Filled button
            loading={loadingCreate} // Pass loading state
            onClick={handleCreateConversation} // Call the create conversation function
            sx={{
              whiteSpace: "nowrap", // Prevents text wrapping inside the button
              // padding: "8px 16px", // Adds padding for better spacing
              minWidth: "auto", // Ensures the button width adjusts to text
              marginRight: currentLang === "ar" ? "16px" : "",
            }}
          />
        </Box>

        {/* Table */}
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{ borderRadius: "16px" }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    backgroundColor: "#000",
                    color: "#fff",
                  }}
                  padding="checkbox"
                >
                  <Checkbox
                    sx={{
                      color: "#fff", // Unchecked color
                      "&.Mui-checked": {
                        color: "#fff", // Checked color
                      },
                      "&.MuiCheckbox-indeterminate": {
                        color: "#fff", // Indeterminate color
                      },
                    }}
                    indeterminate={
                      selected.length > 0 &&
                      selected.length < conversations?.length
                    }
                    checked={
                      conversations?.length > 0 &&
                      selected.length === conversations?.length
                    }
                    onChange={(event) => {
                      event.stopPropagation(); // Prevent row click when selecting all
                      handleSelectAllClick(event);
                    }}
                    inputProps={{ "aria-label": "select all conversations" }}
                  />
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    backgroundColor: "#000",
                    color: "#fff",
                  }}
                >
                  {t("title")}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    backgroundColor: "#000",
                    color: "#fff",
                  }}
                >
                  {t("creation_date")}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    backgroundColor: "#000",
                    color: "#fff",
                  }}
                >
                  {t("last_modified")}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    backgroundColor: "#000",
                    color: "#fff",
                  }}
                >
                  {t("users")}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    backgroundColor: "#000",
                    color: "#fff",
                  }}
                >
                  {t("action")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={6} style={{ textAlign: "center" }}>
                    <CircularProgress size={48} thickness={4} />
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {filteredRows.length > 0 ? (
                    filteredRows.map((row, index) => {
                      const isItemSelected = selected.indexOf(row.id) !== -1;
                      const labelId = `enhanced-table-checkbox-${row.id}`;
                      return (
                        <TableRow
                          hover
                          onClick={(event) => {
                            event.preventDefault(); // Prevent default for normal clicks
                            if (
                              event.ctrlKey ||
                              event.metaKey ||
                              event.button === 1
                            ) {
                              // Let browser handle Ctrl + click or middle-click for opening in a new tab
                              return;
                            }
                            if (event.target.closest("button")) return;
                            handleOpenConversation(row.id); // Handle row click
                          }}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          component="a" // Add anchor component
                          // href={`/conversation/${row.id}`} // Link to the conversation
                          target="_self" // Ensures normal navigation
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onClick={(event) => event.stopPropagation()} // Prevent row click when selecting
                              onChange={(event) =>
                                handleCheckboxClick(event, row.id)
                              }
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </TableCell>
                          <TableCell>
                            <Typography sx={{ fontWeight: 500 }}>
                              {row.title}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            {formatDateWithTimezoneOffset(row.created_at)}
                          </TableCell>
                          <TableCell>
                            {formatDateWithTimezoneOffset(row.updated_at)}
                          </TableCell>
                          <TableCell>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                // justifyContent: "center"
                              }}
                            >
                              {row.users && row.users.length > 0 ? (
                                <>
                                  {row.users.slice(0, 3).map((user, idx) => (
                                    <Tooltip
                                      key={idx}
                                      title={
                                        <div>
                                          <div>{`${user.first_name} ${user.last_name}`}</div>
                                          <div>{user.email}</div>
                                        </div>
                                      }
                                      arrow
                                    >
                                      <Avatar
                                        sx={{
                                          width: 24,
                                          height: 24,
                                          fontSize: "0.8rem",
                                          cursor: "pointer",
                                          marginLeft: idx !== 0 ? -1.5 : 0, // Overlap avatars
                                          zIndex: row.users.length - idx, // Ensure proper stacking
                                        }}
                                      >
                                        {user.first_name[0]}
                                        {user.last_name[0]}
                                      </Avatar>
                                    </Tooltip>
                                  ))}
                                  {row.users.length > 3 && (
                                    <>
                                      <Avatar
                                        sx={{
                                          width: 24,
                                          height: 24,
                                          fontSize: "0.8rem",
                                          cursor: "pointer",
                                          backgroundColor: "#ccc",
                                          marginLeft: -0.5,
                                          zIndex: 0,
                                        }}
                                        onMouseEnter={handlePopoverOpen}
                                        onMouseLeave={handlePopoverClose}
                                      >
                                        +{row.users.length - 3}
                                      </Avatar>
                                      <Popover
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={handlePopoverClose}
                                        anchorOrigin={{
                                          vertical: "bottom",
                                          horizontal: "center",
                                        }}
                                        transformOrigin={{
                                          vertical: "top",
                                          horizontal: "center",
                                        }}
                                        disableRestoreFocus
                                      >
                                        <List
                                          sx={{
                                            maxHeight: 150, // Fixed height
                                            overflowY: "auto", // Scrollable
                                            width: 200, // Set a width for the popover content
                                          }}
                                        >
                                          {row.users
                                            .slice(3)
                                            .map((user, idx) => (
                                              <ListItem key={idx}>
                                                <ListItemText
                                                  primary={`${user.first_name} ${user.last_name}`}
                                                />
                                              </ListItem>
                                            ))}
                                        </List>
                                      </Popover>
                                    </>
                                  )}
                                </>
                              ) : (
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  N/A
                                </Typography>
                              )}
                            </Box>
                          </TableCell>

                          <TableCell>
                            <Button
                              onClick={(event) => {
                                event.stopPropagation(); // Prevent row click event propagation
                                console.log(
                                  "Share button clicked for conversation ID:",
                                  row.id
                                );
                                handleShareConversation(row.id); // Trigger share conversation logic
                              }}
                              startIcon={
                                <ShareIcon
                                  sx={{
                                    marginLeft:
                                      currentLang === "ar" ? "12px" : "",
                                  }}
                                />
                              }
                            >
                              {t("share_conversation")}
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} style={{ textAlign: "center" }}>
                        <div>
                          <ChatIcon
                            style={{ fontSize: 40, color: "lightgray" }}
                          />
                          <div>{t("no_conversations")}</div>
                          <CustomButton
                            text={t("create_conversation")}
                            outlined={false} // Filled button
                            loading={loadingCreate} // Pass loading state
                            onClick={handleCreateConversation}
                            sx={{ mt: 4, backgroundColor: colors.gray_3 }}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Pagination */}

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "24px",
          }}
        >
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ textAlign: currentLang === "ar" ? "right" : "left" }} // Adjust alignment
          >
            {`${totalCount} ${t("results_count")}`}
          </Typography>
          <Pagination
            count={Math.ceil(totalCount / rowsPerPage)}
            page={page}
            onChange={handlePageChange}
            color="primary"
            sx={{
              borderRadius: "8px",
              alignSelf: currentLang === "ar" ? "flex-start" : "flex-end", // Adjust alignment
              ...(currentLang === "ar" && {
                transform: "scaleX(-1)", // Flip arrows for RTL
                "& .MuiPaginationItem-root": {
                  transform: "scaleX(-1)", // Re-flip text inside pagination items
                },
              }),
            }}
          />
        </Box>
      </Paper>
    </Box>
  );
};

export default ChatHistory;
