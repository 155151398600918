import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Paper, Button } from "@mui/material";
import { ResponsiveLine } from "@nivo/line";
import { colors } from "../../utils/colors";
import { fontSize } from "../../utils/fonts";
import { styles } from "../../utils/styles";
import { backendClient } from "../../api/backend";
import { useSelector } from "react-redux";
import { addMonths, format } from "date-fns"; // Add date-fns for date manipulation
import { windowWidth } from "../../utils/utils";
import { clearStore } from "../../redux/store";
import { useTranslation } from "react-i18next";

const DashboardScreen = () => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;

  const user = useSelector((state) => state.auth.user?.user);
  const [totalConversations, setTotalConversations] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [conversationsOverTime, setConversationsOverTime] = useState([]);
  const [totalSearchs, setTotalSearchs] = useState(0);
  const [totalUsersSearchs, setTotalUsersSearchs] = useState(0);
  const [searchsOverTime, setSearchOverTime] = useState([]);

  // Helper function to generate the last 4 months with 0 conversations as defaults
  const getLastFourMonths = () => {
    const currentDate = new Date();
    const months = [];

    for (let i = 0; i < 4; i++) {
      const monthDate = addMonths(currentDate, -i);
      months.unshift({ x: format(monthDate, "MMM"), y: 0 });
    }

    return months;
  };

  // Combine the fetched data with default months if necessary
  const ensureFourMonths = (fetchedData) => {
    const lastFourMonths = getLastFourMonths();

    const filledData = [...lastFourMonths];

    fetchedData.forEach((item) => {
      const index = filledData.findIndex((month) => month.x === item.x);
      if (index !== -1) {
        filledData[index].y = item.y; // Replace 0 with actual data if available
      }
    });

    return filledData;
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const { totalConversations, totalUsers } =
          await backendClient.getConversationsAndUsers(user?.organization_name);
        setTotalConversations(totalConversations);
        setTotalUsers(totalUsers);

        const { totalSearchs, totalUsersSearchs } =
          await backendClient.getSearchesAndUsers(user?.organization_name);
        setTotalSearchs(totalSearchs);
        setTotalUsersSearchs(totalUsersSearchs);

        const conversationsData = await backendClient.getConversationsOverTime(
          user?.organization_name
        );
        if (conversationsData && Array.isArray(conversationsData)) {
          // Ensure the data contains at least 4 months
          const filledData = ensureFourMonths(conversationsData[0].data);
          setConversationsOverTime([{ id: "conversation", data: filledData }]);
        }

        const searchsData = await backendClient.getSearchesOverTime(
          user?.organization_name
        );

        if (searchsData && Array.isArray(searchsData)) {
          // Ensure the data contains at least 4 months
          const filledData = ensureFourMonths(searchsData[0].data);
          setSearchOverTime([{ id: "search", data: filledData }]);
        }
      } catch (error) {
        console.error("Error fetching data", error);
      }
    }

    fetchData();
  }, [user?.organization_name]);

  const dataConversation = conversationsOverTime.length
    ? conversationsOverTime
    : [
        {
          id: "conversation",
          data: getLastFourMonths(),
        },
      ];
  const dataSearch = searchsOverTime.length
    ? searchsOverTime
    : [
        {
          id: "search",
          data: getLastFourMonths(),
        },
      ];

  const overviewData = [
    { value: "+16k", label: t("documents_available") },
    { value: "+35k", label: t("jurisprudence_cases") },
    { value: "+300k", label: t("legal_articles") },
    { value: "+1m", label: t("graph_nodes") },
  ];

  // console.log("TOT", t("documents_available"));

  // clearStore();

  return (
    <Grid container sx={{ background: colors.bg_color }}>
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12}>
          <Paper
            elevation={3}
            sx={{
              p: { xs: 2, sm: 3 },
              borderRadius: 2,
            }}
            dir={currentLang === "ar" ? "rtl" : "ltr"}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 600,
                mb: { xs: 2, sm: 3 },
                fontSize: { xs: "1.1rem", sm: "1.25rem" },
              }}
            >
              {t("data_summary")}
            </Typography>

            <Grid container spacing={{ xs: 2, sm: 3 }}>
              {overviewData.map((item, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <Box
                    sx={{
                      textAlign: currentLang === "ar" ? "right" : "left",
                      // textAlign: { xs: "left", sm: "center" },
                      display: "flex",
                      flexDirection: { xs: "row", sm: "column" },
                      alignItems: { xs: "center", sm: "center" },
                      gap: { xs: 2, sm: 1 },
                      p: { xs: 2, sm: 1 },
                      borderRadius: 1,
                      backgroundColor: "background.paper",
                      "&:hover": {
                        backgroundColor: "action.hover",
                      },
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: 700,
                        fontSize: { xs: "1.75rem", sm: "2rem", md: "2.25rem" },
                        minWidth: { xs: "90px", sm: "auto" },
                      }}
                    >
                      {item.value}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color="textSecondary"
                      sx={{
                        fontSize: { xs: "0.8rem", sm: "0.875rem" },
                        lineHeight: { xs: 1.3, sm: 1.5 },
                        mt: { xs: 0, sm: 1 },
                      }}
                    >
                      {item.label}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        {/* Search Breakdown */}
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{
              padding: styles.main_container_padding,
              borderRadius: styles.main_container_radius,
              direction: currentLang === "ar" ? "rtl" : "ltr", // Set direction dynamically
            }}
          >
            <Grid container justifyContent="space-between">
              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                {t("searches")}
              </Typography>
              {/* <Button
                variant="contained"
                color="primary"
                sx={{ borderRadius: "8px" }}
              >
                Vue administrateur
              </Button> */}
            </Grid>

            {/* Total Conversation and Total Users */}
            <Grid container spacing={2} sx={{ marginTop: "16px" }}>
              <Grid item xs={6}>
                <Typography variant="h4" sx={{ fontWeight: 700 }}>
                  {totalSearchs}
                </Typography>
                <Typography variant="subtitle2" color="textSecondary">
                  {t("total_searches")}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h4" sx={{ fontWeight: 700 }}>
                  {totalUsersSearchs}
                </Typography>
                <Typography variant="subtitle2" color="textSecondary">
                  {t("users")}
                </Typography>
              </Grid>
            </Grid>

            {/* Search Over Time */}
            <Box sx={{ height: 250, marginTop: "16px" }}>
              <ResponsiveLine
                data={dataSearch}
                margin={{ top: 20, right: 20, bottom: 50, left: 50 }}
                xScale={{ type: "point" }}
                yScale={{
                  type: "linear",
                  min: 0,
                  max: totalSearchs + 20,
                  stacked: true,
                }}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: t("tooltip_month"),
                  legendOffset: 36,
                  legendPosition: "middle",
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: t("tooltip_search"),
                  legendOffset: -40,
                  legendPosition: "middle",
                }}
                colors={{ scheme: "category10" }}
                pointSize={10}
                pointColor={{ theme: "background" }}
                pointBorderWidth={2}
                pointBorderColor={{ from: "serieColor" }}
                pointLabelYOffset={-12}
                useMesh={true}
                tooltip={({ point }) => (
                  <div
                    style={{
                      background: "rgba(255, 255, 255, 0.9)",
                      padding: "8px",
                      borderRadius: "4px",
                      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.15)",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {/* Larger circle with the same color as the line */}
                    <span
                      style={{
                        display: "inline-block",
                        width: windowWidth / 80, // Increased circle size
                        height: windowWidth / 80, // Increased circle size
                        borderRadius: "50%",
                        backgroundColor: point.serieColor,
                        marginRight: "8px",
                      }}
                    ></span>
                    <div style={{ fontSize: "12px" }}>
                      {" "}
                      {/* Smaller text size */}
                      <span>{t("tooltip_search")}: </span>
                      <strong>{point.data.yFormatted}</strong>
                      <br />
                      <span>{t("tooltip_month")}: </span>
                      <strong>{point.data.x}</strong>
                    </div>
                  </div>
                )}
              />
            </Box>
          </Paper>
        </Grid>

        {/* Conversation Breakdown */}
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{
              padding: styles.main_container_padding,
              borderRadius: styles.main_container_radius,
              direction: currentLang === "ar" ? "rtl" : "ltr", // Set direction dynamically
            }}
          >
            <Grid container justifyContent="space-between">
              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                {t("conversations")}
              </Typography>
              {/* <Button
                variant="contained"
                color="primary"
                sx={{ borderRadius: "8px" }}
              >
                Vue administrateur
              </Button> */}
            </Grid>

            {/* Total Conversation and Total Users */}
            <Grid container spacing={2} sx={{ marginTop: "16px" }}>
              <Grid item xs={6}>
                <Typography variant="h4" sx={{ fontWeight: 700 }}>
                  {totalConversations}
                </Typography>
                <Typography variant="subtitle2" color="textSecondary">
                  {t("total_conversations")}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h4" sx={{ fontWeight: 700 }}>
                  {totalUsers}
                </Typography>
                <Typography variant="subtitle2" color="textSecondary">
                  {t("users")}
                </Typography>
              </Grid>
            </Grid>

            {/* Conversation Over Time */}
            <Box sx={{ height: 250, marginTop: "16px" }}>
              <ResponsiveLine
                data={dataConversation}
                margin={{ top: 20, right: 20, bottom: 50, left: 50 }}
                xScale={{ type: "point" }}
                yScale={{
                  type: "linear",
                  min: 0,
                  max: totalConversations + 20,
                  stacked: true,
                }}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: t("tooltip_month"),
                  legendOffset: 36,
                  legendPosition: "middle",
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: t("conversations"),
                  legendOffset: -40,
                  legendPosition: "middle",
                }}
                colors={{ scheme: "category10" }}
                pointSize={10}
                pointColor={{ theme: "background" }}
                pointBorderWidth={2}
                pointBorderColor={{ from: "serieColor" }}
                pointLabelYOffset={-12}
                useMesh={true}
                tooltip={({ point }) => (
                  <div
                    style={{
                      background: "rgba(255, 255, 255, 0.9)",
                      padding: "8px",
                      borderRadius: "4px",
                      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.15)",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {/* Larger circle with the same color as the line */}
                    <span
                      style={{
                        display: "inline-block",
                        width: windowWidth / 80, // Increased circle size
                        height: windowWidth / 80, // Increased circle size
                        borderRadius: "50%",
                        backgroundColor: point.serieColor,
                        marginRight: "8px",
                      }}
                    ></span>
                    <div style={{ fontSize: "12px" }}>
                      {" "}
                      {/* Smaller text size */}
                      <span>{t("conversation")}: </span>
                      <strong>{point.data.yFormatted}</strong>
                      <br />
                      <span>{t("tooltip_month")}: </span>
                      <strong>{point.data.x}</strong>
                    </div>
                  </div>
                )}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DashboardScreen;
