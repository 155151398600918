import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  TextField,
  Typography,
  Container,
  Paper,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import {
  clearMessageError,
  resetPassword,
} from "../../redux/actions/authAction";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import CustomButton from "../../components/CustomButton";
import { colors } from "../../utils/colors";

const ResetPasswordScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token");
  const { loading, error, message } = useSelector((state) => state.auth);

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State to handle password visibility

  const handleResetPassword = () => {
    if (newPassword !== confirmPassword) {
      setPasswordError(true);
      return;
    }
    dispatch(resetPassword(token, newPassword));
  };

  useEffect(() => {
    dispatch(clearMessageError());
  }, [dispatch]);

  return (
    <Grid
      container
      sx={{
        height: "90vh",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.bg_color,
      }}
    >
      <Paper
        elevation={4}
        sx={{ borderRadius: "20px", width: "80%", height: "80vh" }}
      >
        <Grid container sx={{ height: "100%" }}>
          <Grid
            item
            xs={12}
            md={5}
            sx={{
              backgroundColor: "black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "160px",
                height: "160px",
                backgroundColor: "white",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Logo style={{ width: "140px", height: "140px" }} />
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            md={7}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Container maxWidth="xs">
              <Typography variant="h4" sx={{ mb: 4 }}>
                Réinitialiser le mot de passe
              </Typography>

              {error && (
                <Typography color="error" variant="body1">
                  {error}
                </Typography>
              )}

              {message && (
                <Typography color="success.main" variant="body1">
                  {message}
                </Typography>
              )}

              <TextField
                required
                fullWidth
                type={showPassword ? "text" : "password"} // Toggle password visibility
                label="Nouveau mot de passe"
                margin="normal"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  ),
                }}
              />

              <TextField
                required
                fullWidth
                type={showPassword ? "text" : "password"} // Toggle password visibility
                label="Confirmer le mot de passe"
                margin="normal"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                error={passwordError}
                helperText={
                  passwordError ? "Les mots de passe ne correspondent pas." : ""
                }
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  ),
                }}
              />

              <CustomButton
                text="Réinitialiser le mot de passe"
                outlined={false}
                loading={loading}
                onClick={handleResetPassword}
                sx={{ mt: 4, width: "100%" }}
              />

              <Typography
                variant="body2"
                sx={{ textAlign: "center", cursor: "pointer", mt: 2 }}
                onClick={() => navigate("/login")}
              >
                Retour à la connexion
              </Typography>
            </Container>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default ResetPasswordScreen;
