import React, { useRef, useState, useEffect } from "react";
import { Button, CircularProgress } from "@mui/material";

const CustomButton = ({
  text = "Button", // Default text
  outlined = false, // Default to filled button
  loading = false, // Default to not loading
  onClick, // Pass custom onClick behavior
  sx = {}, // Custom sx prop from parent (defaults to empty object)
  ...props // Spread other props such as size, color, etc.
}) => {
  const [dimensions, setDimensions] = useState({
    width: "auto",
    height: "auto",
  });
  const buttonRef = useRef(null);

  // Set button dimensions based on its content
  useEffect(() => {
    if (buttonRef.current && !loading) {
      const { offsetWidth, offsetHeight } = buttonRef.current;
      setDimensions({ width: offsetWidth, height: offsetHeight });
    }
  }, [loading, text]);

  return (
    <Button
      ref={buttonRef}
      variant={outlined ? "outlined" : "contained"} // Choose between outlined or filled button
      onClick={onClick}
      disabled={loading} // Disable the button when loading
      sx={{
        backgroundColor: outlined ? "transparent" : "black",
        color: outlined ? "black" : "white",
        border: outlined ? "2px solid black" : "none",
        borderRadius: "8px",
        padding: "10px 20px",
        textTransform: "none", // Prevent uppercase transformation of text
        fontWeight: "bold",
        position: "relative", // For proper positioning of the loading spinner
        width: loading ? `${dimensions.width}px` : "auto", // Maintain button width during loading
        height: loading ? `${dimensions.height}px` : "auto", // Maintain button height during loading
        ":hover": {
          backgroundColor: outlined
            ? "rgba(0, 0, 0, 0.1)"
            : "rgba(0, 0, 0, 0.8)",
        },
        // Spread any custom styles passed through the sx prop
        ...sx,
      }}
      {...props} // Allow other props such as size, color, etc. to be passed
    >
      {loading ? (
        <CircularProgress
          size={24} // Adjust size of the spinner
          color="inherit" // Inherit the color of the button
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px", // Center the spinner vertically
            marginLeft: "-12px", // Center the spinner horizontally
          }}
        />
      ) : (
        text
      )}
    </Button>
  );
};

export default CustomButton;
