export const fontSize = {
  xsmall: "10px",
  small: "12px",
  normal: "14px",
  medium: "16px",
  large: "18px",
  xlarge: "24px",
  xxlarge: "32px",
  title: "36px",
  subtitle: "28px",
};
