import React, { useState, useEffect, useMemo, lazy, Suspense } from "react";
import SubProcessDisplay from "./SubProcessDisplay";
import { Edit as EditIcon } from "@mui/icons-material";
import useConversationsStore from "./useConversationsStore";
import ErrorMessageDisplay from "./ErrorMessageDisplay";
import { TextDirection } from "../../../utils/constants";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

const LazyTextEditor = lazy(() => import("../../../components/QuillEditor"));

const AssistantDisplay = ({ message, documents }) => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const currentDirection = currentLang === "ar" ? "rtl" : "ltr";

  const { editMessage, checkMessage } = useConversationsStore();
  const [isExpanded, setIsExpanded] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [content, setContent] = useState(message.content);

  const DynamicTextEditor = useMemo(() => {
    return (
      <Suspense fallback={<p>{t("loading")}</p>}>
        <LazyTextEditor value={content} setValue={setContent} />
      </Suspense>
    );
  }, [content, t]);

  const isMessageSuccessful = message.status === "SUCCESS";
  const isMessageError = message.status === "ERROR";

  useEffect(() => {
    setContent(message.content);
  }, [message]);

  useEffect(() => {
    if (isMessageSuccessful) {
      setIsExpanded(false);
    }
  }, [isMessageSuccessful]);

  const handleSave = () => {
    editMessage(message.id, content);
    setIsEditing(false);
  };

  const parseTableToHTML = (text) => {
    const cleanedText = text.replace(/<br\/?>/g, "\n");
    const lines = cleanedText.trim().split("\n");

    if (lines.length < 2 || !lines[1].match(/^\|[-|]+\|$/)) {
      return null;
    }

    const headers = lines[0]
      .split("|")
      .slice(1, -1)
      .map((header) => header.trim());

    const rows = lines
      .slice(2)
      .filter((line) => line.includes("|"))
      .map((line) =>
        line
          .split("|")
          .slice(1, -1)
          .map((cell) => cell.trim())
      );

    let tableHTML =
      "<table style='width: 100%; border-collapse: collapse; margin-top: 16px;'>";
    tableHTML += "<thead><tr>";
    headers.forEach(
      (header) =>
        (tableHTML += `<th style='border: 1px solid #ddd; padding: 8px; text-align: left;'>${header}</th>`)
    );
    tableHTML += "</tr></thead><tbody>";
    rows.forEach((row) => {
      tableHTML += "<tr>";
      row.forEach(
        (cell) =>
          (tableHTML += `<td style='border: 1px solid #ddd; padding: 8px;'>${cell}</td>`)
      );
      tableHTML += "</tr>";
    });
    tableHTML += "</tbody></table>";

    return tableHTML;
  };

  const formatTextToHTML = (text) => {
    const cleanedText = text.replace(/<br\/?>/g, "\n");
    const sections = cleanedText.split("\n\n");

    let htmlContent = "";

    sections.forEach((section) => {
      const tableHTML = parseTableToHTML(section);
      if (tableHTML) {
        htmlContent += tableHTML;
      } else {
        let formattedText = section.replace(
          /\*\*(.*?)\*\*/g,
          "<strong>$1</strong>"
        );
        formattedText = formattedText.replace(/\*(.*?)\*/g, "<em>$1</em>");
        formattedText = formattedText.replace(/\n/g, "<br/>");
        htmlContent += `<p style="margin-top: 12px;">${formattedText}</p>`;
      }
    });

    return htmlContent;
  };

  return (
    <div
      className="pb-4 pr-5"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      style={{ direction: currentDirection }}
    >
      <div className="flex">
        <div className="pl-11 w-full">
          {!isMessageError && (
            <div className="flex flex-col flex-grow">
              <SubProcessDisplay
                key={`${message.id}-sub-process`}
                subProcesses={message.sub_processes || []}
                isOpen={isExpanded}
                toggleOpen={() => setIsExpanded((prev) => !prev)}
                showSpinner={!isMessageSuccessful}
                messageId={message.id}
                documents={documents}
                like={message.like}
              />
            </div>
          )}
          {isMessageError && <ErrorMessageDisplay />}
        </div>
      </div>

      {!isMessageError && (
        <>
          <div className="flex items-center justify-center">
            <div className="my-3 w-11/12 border-[.5px]"></div>
          </div>
          <div className="flex items-center">
            <div className={` ${currentLang === "ar" ? "" : ""}`}>
              {!isEditing && message.status === "SUCCESS" && (
                <p
                  className={`flex cursor-pointer items-center ${
                    currentLang === "ar" ? "" : "pl-2 pr-2"
                  }`}
                >
                  <label
                    className={`flex items-center ${
                      currentLang === "ar" ? "justify-start" : "justify-end"
                    }`}
                  >
                    <input
                      className={currentLang === "ar" ? "" : ""}
                      type="checkbox"
                      checked={message?.checked || false}
                      onChange={() => checkMessage(message.id)}
                    />
                    {/* {t("export")} */}
                  </label>
                </p>
              )}
            </div>

            {isEditing ? (
              <div className="w-full">
                {DynamicTextEditor}
                <div className="my-2 flex justify-end gap-3">
                  <span
                    onClick={handleSave}
                    className={`cursor-pointer text-sm underline ${
                      currentLang === "ar" ? "ml-auto" : "mr-auto"
                    }`}
                  >
                    {t("save")}
                  </span>
                  <span
                    onClick={() => setIsEditing(false)}
                    className="cursor-pointer text-sm underline"
                  >
                    {t("cancel")}
                  </span>
                </div>
              </div>
            ) : (
              <div className="w-full">
                {!isEditing && message.status === "SUCCESS" && (
                  <p
                    className={`flex w-20 cursor-pointer items-center gap-2 ${
                      currentLang === "ar" ? "ml-auto" : "mr-auto"
                    }`}
                    onClick={() => setIsEditing(true)}
                  >
                    <EditIcon /> {t("edit")}
                  </p>
                )}
                <div className="w-full font-nunito text-sm text-gray-90">
                  <p
                    className="ql-editor w-full whitespace-pre-wrap font-nunito text-sm text-gray-90"
                    style={{
                      direction: currentDirection,
                      textAlign: currentLang === "ar" ? "right" : "left",
                      width: "100%",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: formatTextToHTML(message.content),
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </>
      )}

      <p
        className="flex ml-10 justify-start p-1 text-xs text-gray-60"
        style={{ direction: currentDirection }}
      >
        {t("disclaimer")}
      </p>
    </div>
  );
};

export default AssistantDisplay;
