import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { getDateWithUTCOffset } from "../utils/timezone";
import { MESSAGE_STATUS, ROLE } from "../utils/constants";

const useMessages = (conversationId) => {
  const [messages, setMessages] = useState([]);


  const userSendMessage = (content) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        id: uuidv4(),
        conversationId,
        content,
        role: ROLE.USER,
        status: MESSAGE_STATUS.SUCCESS,
        created_at: getDateWithUTCOffset(),
      },
    ]);
  };

  const systemSendMessage = (message) => {
    setMessages((prevMessages) => {
      const existingMessageIndex = prevMessages.findIndex((msg) => msg.id === message.id);

      // Update the existing message
      if (existingMessageIndex > -1) {
        const updatedMessages = [...prevMessages];
        updatedMessages[existingMessageIndex] = message;
        return updatedMessages;
      }

      // Add a new message if it doesn't exist
      return [...prevMessages, message];
    });
  };

  return {
    messages,
    userSendMessage,
    setMessages,
    systemSendMessage,
  };
};

export default useMessages;
