import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: "fr", // Default language
    lng: "fr", // Set French as the initial language
    debug: true, // Debug mode
    interpolation: {
      escapeValue: false, // React already does escaping
    },
    resources: {
      fr: {
        translation: require("./locales/fr.json"),
      },
      ar: {
        translation: require("./locales/ar.json"),
      },
      en: {
        translation: require("./locales/en.json"),
      },
    },
  });

export default i18n;
