import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  TextField,
  Typography,
  Container,
  Paper,
  IconButton,
  Checkbox,
  FormControlLabel,
  Divider,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { clearMessageError, signUp } from "../../redux/actions/authAction";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import CustomButton from "../../components/CustomButton";
import { colors } from "../../utils/colors";
import { isMobile } from "../../utils/utils";

const SignupScreen = () => {
  const dispatch = useDispatch();
  const { loading, error, user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [loadingButton, setLoadingButton] = useState(false);

  const [last_name, setLastName] = useState("");
  const [first_name, setFirstName] = useState("");
  const [organization_name, setOrganizationName] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);

  // Error states for validation
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const isMobileView = isMobile();

  const handleSignup = async () => {
    setLoadingButton(true);
    // Validate required fields
    setLastNameError(last_name.trim() === "");
    setFirstNameError(first_name.trim() === "");

    const personalEmailDomains = [
      // "gmail.com",
      // "yahoo.com",
      // "hotmail.com",
      // "outlook.com",
      // "aol.com",
      // "icloud.com",
      // "live.com",
      // "protonmail.com",
      // "mail.com",
      // "yandex.com",
      // "zoho.com",
      // "gmx.com",
      // "me.com",
      // "fastmail.com",
      // "hushmail.com",
      // "tutanota.com",
      // "posteo.net",
      // "qq.com",
      // "126.com",
      // "163.com",
      // "rediffmail.com",
      // "lycos.com",
      // "inbox.com",
      // "lavabit.com",
      // "rocketmail.com",
      // "excite.com",
      // "bluewin.ch",
      // "bigpond.com",
      // "shaw.ca",
      // "telus.net",
      // "cox.net",
      // "charter.net",
      // "comcast.net",
      // "verizon.net",
      // "att.net",
      // "bellsouth.net",
      // "earthlink.net",
      // "sbcglobal.net",
      // "btinternet.com",
      // "virginmedia.com",
      // "sky.com",
      // "talktalk.net",
      // "optonline.net",
      // "web.de",
      // "freenet.de",
      // "zoznam.sk",
      // "seznam.cz",
      // "mail.ru",
      // "rambler.ru",
      // "bk.ru",
      // "list.ru",
      // "inbox.lv",
      // "onet.pl",
      // "wp.pl",
      // "interia.pl",
      // "o2.pl",
      // "centrum.cz",
      // "volny.cz",
      // "orange.fr",
      // "wanadoo.fr",
      // "laposte.net",
      // "free.fr",
      // "sfr.fr",
      // "aliceadsl.fr",
      // "neuf.fr",
    ];

    // Extract the domain from the email
    const emailDomain = email.split("@")[1];

    // Check if email is personal
    const isPersonalEmail = personalEmailDomains.includes(emailDomain);

    if (isPersonalEmail) {
      setEmailError(
        "L'adresse e-mail fournie est une adresse personnelle. Veuillez entrer une adresse e-mail professionnelle."
      );
      setLoadingButton(false);
      return;
    }
    // Validate email
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    if (!isValidEmail) {
      setEmailError("Veuillez entrer une adresse e-mail valide.");
    }

    // Validate phone number (simple format, can be improved based on country)
    const isValidPhone = /^[0-9]{10}$/.test(phone_number);
    setPhoneError(!isValidPhone);

    // Validate password (at least 6 characters, contains at least one special character)
    const isValidPassword =
      password.length >= 6 && /[!@#$%^&*(),.+?":{}|<>]/.test(password);
    setPasswordError(!isValidPassword);

    // Check if all validations pass
    if (
      last_name.trim() !== "" &&
      first_name.trim() !== "" &&
      isValidEmail &&
      isValidPhone &&
      isValidPassword &&
      password === confirmPassword &&
      acceptTerms
    ) {
      dispatch(
        signUp(
          last_name,
          first_name,
          organization_name,
          phone_number,
          email,
          password,
          navigate
        )
      );
      setLoadingButton(false);
    } else if (password !== confirmPassword) {
      alert("Les mots de passe ne correspondent pas");
      setLoadingButton(false);
    }
  };

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user, navigate]);

  useEffect(() => {
    // Clear message and error state when this component mounts
    dispatch(clearMessageError());
  }, [dispatch]);

  return (
    <Grid
      container
      sx={{
        height: "90vh",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.bg_color,
      }}
    >
      <Paper
        elevation={4}
        sx={{
          borderRadius: isMobileView ? "" : "20px",
          width: isMobileView ? "100%" : "80%",
          height: "90vh",
        }}
      >
        <Grid container sx={{ height: "100%" }}>
          <Grid
            item
            xs={12}
            md={5}
            sx={{
              backgroundColor: "black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: isMobileView ? "100px" : "160px",
                height: isMobileView ? "100px" : "160px",
                backgroundColor: "white",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: isMobileView ? "10px" : "",
              }}
            >
              <Logo
                style={{
                  width: isMobileView ? "80px" : "140px",
                  height: isMobileView ? "80px" : "140px",
                }}
              />
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            md={7}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Container maxWidth="xs">
              <Typography
                variant={isMobileView ? "h5" : "h4"}
                sx={{ mb: isMobileView ? 1 : 4, mt: isMobileView ? 2 : "" }}
              >
                S'inscrire
              </Typography>

              {error && (
                <Typography color="error" variant="body1">
                  {error}
                </Typography>
              )}
              <Grid container spacing={isMobileView ? 0 : 2}>
                <Grid item xs={isMobileView ? 12 : 6}>
                  <TextField
                    fullWidth
                    label="Nom"
                    margin={isMobileView ? "dense" : "normal"}
                    value={last_name}
                    onChange={(e) => setLastName(e.target.value)}
                    error={lastNameError}
                    helperText={lastNameError ? "Ce champ est requis." : ""}
                    required
                    placeholder="Entrez votre nom"
                    InputProps={{
                      style: {
                        fontSize: isMobileView ? "14px" : "16px", // Input text size
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: isMobileView ? "14px" : "16px", // Input text size
                      },
                    }}
                    sx={{
                      "& .MuiInputBase-input::placeholder": {
                        fontSize: isMobileView ? "12px" : "14px", // Placeholder text size
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={isMobileView ? 12 : 6}>
                  <TextField
                    fullWidth
                    label="Prénom"
                    margin={isMobileView ? "dense" : "normal"}
                    value={first_name}
                    onChange={(e) => setFirstName(e.target.value)}
                    error={firstNameError}
                    helperText={firstNameError ? "Ce champ est requis." : ""}
                    required
                    placeholder="Entrez votre prénom"
                    InputProps={{
                      style: {
                        fontSize: isMobileView ? "14px" : "16px",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: isMobileView ? "14px" : "16px", // Input text size
                      },
                    }}
                    sx={{
                      "& .MuiInputBase-input::placeholder": {
                        fontSize: isMobileView ? "12px" : "14px",
                      },
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={isMobileView ? 0 : 2}>
                <Grid item xs={isMobileView ? 12 : 6}>
                  <TextField
                    fullWidth
                    label="Entreprise"
                    margin={isMobileView ? "dense" : "normal"}
                    value={organization_name}
                    onChange={(e) => setOrganizationName(e.target.value)}
                    required
                    placeholder="Entrez le nom de l'entreprise"
                    InputProps={{
                      style: {
                        fontSize: isMobileView ? "14px" : "16px",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: isMobileView ? "14px" : "16px", // Input text size
                      },
                    }}
                    sx={{
                      "& .MuiInputBase-input::placeholder": {
                        fontSize: isMobileView ? "12px" : "14px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={isMobileView ? 12 : 6}>
                  <TextField
                    fullWidth
                    label="Téléphone"
                    margin={isMobileView ? "dense" : "normal"}
                    value={phone_number}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    error={phoneError}
                    helperText={
                      phoneError ? "Numéro de téléphone invalide." : ""
                    }
                    required
                    placeholder="Entrez votre numéro de téléphone"
                    InputProps={{
                      style: {
                        fontSize: isMobileView ? "14px" : "16px",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: isMobileView ? "14px" : "16px", // Input text size
                      },
                    }}
                    sx={{
                      "& .MuiInputBase-input::placeholder": {
                        fontSize: isMobileView ? "12px" : "14px",
                      },
                    }}
                  />
                </Grid>
              </Grid>

              {/* Email Field */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  margin={isMobileView ? "dense" : "normal"}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError(false);
                  }}
                  error={!!emailError}
                  helperText={emailError}
                  required
                  placeholder="Entrez votre adresse email"
                  InputProps={{
                    style: {
                      fontSize: isMobileView ? "14px" : "16px",
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      fontSize: isMobileView ? "14px" : "16px", // Input text size
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-input::placeholder": {
                      fontSize: isMobileView ? "12px" : "14px",
                    },
                  }}
                />
              </Grid>

              {/* Password Fields */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Mot de passe"
                  margin={isMobileView ? "dense" : "normal"}
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  error={passwordError}
                  helperText={
                    passwordError
                      ? "Le mot de passe doit comporter au moins 6 caractères et contenir un caractère spécial."
                      : ""
                  }
                  placeholder="Entrez votre mot de passe"
                  InputProps={{
                    style: {
                      fontSize: isMobileView ? "14px" : "16px",
                    },
                    endAdornment: (
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    ),
                  }}
                  InputLabelProps={{
                    style: {
                      fontSize: isMobileView ? "14px" : "16px", // Input text size
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-input::placeholder": {
                      fontSize: isMobileView ? "12px" : "14px",
                    },
                  }}
                  required
                />

                <TextField
                  fullWidth
                  label="Confirmer le mot de passe"
                  margin="normal"
                  type={showPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  error={password !== confirmPassword && confirmPassword !== ""}
                  helperText={
                    password !== confirmPassword && confirmPassword !== ""
                      ? "Les mots de passe ne correspondent pas."
                      : ""
                  }
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    ),
                  }}
                  required
                />
              </Grid>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={acceptTerms}
                    onChange={(e) => setAcceptTerms(e.target.checked)}
                    required
                  />
                }
                label={
                  <span>
                    J'accepte les{" "}
                    <a
                      href="https://juridia.ma/condition-generales-de-vente/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: colors.primary, // Replace with your primary color if needed
                        textDecoration: "underline",
                      }}
                    >
                      conditions générales de vente
                    </a>
                  </span>
                }
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: isMobileView ? "11px" : "16px", // Adjust font size based on screen size
                  },
                  width: "100%",
                }}
              />

              <CustomButton
                text="S'inscrire"
                outlined={false}
                loading={loading}
                onClick={handleSignup}
                disabled={!acceptTerms} // Disable button if terms are not accepted
                sx={{
                  mt: isMobileView ? 5 : 4,
                  mb: isMobileView ? 3 : "",
                  width: "100%",
                }}
              />

              <Divider
                sx={{
                  width: "100%",
                  mt: isMobileView ? 0 : 3,
                  mb: 2,
                }}
              />

              <Typography
                variant="body2"
                sx={{
                  textAlign: "center",
                  cursor: "pointer",
                  mt: 2,
                  mb: isMobileView ? 4 : 2,
                }}
              >
                Ou{" "}
                <span
                  onClick={() => navigate("/login")}
                  style={{
                    color: colors.primary, // Replace with your primary color if needed
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  connectez-vous
                </span>
              </Typography>
            </Container>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default SignupScreen;
