import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Tooltip,
  Box,
  Button,
} from "@mui/material";
import {
  Dashboard as DashboardIcon,
  Search as SearchIcon,
  Chat as ChatIcon,
  Settings as SettingsIcon,
  Close as CloseIcon,
  Menu as MenuIcon,
  Add as AddIcon,
} from "@mui/icons-material";
import { ReactComponent as Logo } from "../assets/logo.svg"; // Import the SVG as a React component
import { Link, useLocation, useNavigate } from "react-router-dom";
import { colors } from "../utils/colors";
import { setOpenNavBar } from "../redux/actions/navbarAction";
import { useSnackbar } from "notistack";
import { createConversation } from "../redux/actions/conversationAction";
import { fontSize } from "../utils/fonts";
import useDocumentStore from "../screens/ChatScreen/components/useDocumentStore";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

const Navbar = () => {
    const { t, i18n } = useTranslation();
  const currentLang = i18n.language;


  const dispatch = useDispatch();
  const navigate = useNavigate();
  const open = useSelector((state) => state.navbar.isOpen);
  const user = useSelector((state) => state.auth?.user?.user);
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { setSelectedCitation } = useDocumentStore();

  const menuItems = [
    { text: t("dashboard"), icon: <DashboardIcon />, link: "/" },
    { text: t("search"), icon: <SearchIcon />, link: "/search" },
    { text: t("conversation"), icon: <ChatIcon />, link: "/conversation" },
  ];

  const handleMenuClick = (path, itemText) => {
    ReactGA.event({
      category: "Navigation",
      action: `Clicked on ${itemText}`,
      label: `Path: ${path}`,
    });
    navigate(path);
  };

  const toggleDrawer = () => {
    dispatch(setOpenNavBar(!open)); // Dispatch action to toggle the navbar open state
  };

  const handleCreateConversation = async () => {
    try {
      const newConversation = await dispatch(createConversation(user.id)); // Dispatch create conversation
      if (newConversation) {
        setSelectedCitation([]);
        enqueueSnackbar("Conversation créée.", {
          variant: "success",
        });

        // Google Analytics event for conversation creation
        ReactGA.event({
          category: "Conversation",
          action: "User created a new conversation",
          label: `User ID: ${user.id}`,
        });

        navigate(`/conversation/${newConversation.id}`); // Navigate to the new conversation
      }
    } catch (error) {
      enqueueSnackbar("Failed to create conversation", { variant: "error" });
    }
  };

  const isItemSelected = (path) =>
    location.pathname === path ||
    location.pathname.split("/")[1] === path.split("/")[1];

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: open ? 240 : 60,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: open ? 240 : 60,
          transition: "width 0.4s ease-in-out",
          overflowX: "hidden",
          backgroundColor: colors.black,
          color: colors.white,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        },
      }}
    >
      <Box>
        <List>
          <ListItem
            component={Link}
            to="/"
            sx={{
              justifyContent: "center",
              alignItems: "center",
              padding: open ? "16px" : "8px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                width: "50px",
                height: "50px",
                backgroundColor: "white",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Logo
                style={{
                  width: "40px",
                  height: "40px",
                }}
              />
            </div>
          </ListItem>

          <ListItem
            onClick={toggleDrawer}
            sx={{
              justifyContent: open ? "flex-end" : "center",
              padding: open ? "8px 16px" : "8px 0",
            }}
          >
            <IconButton>
              {open ? (
                <CloseIcon sx={{ color: colors.white }} />
              ) : (
                <MenuIcon sx={{ color: colors.white }} />
              )}
            </IconButton>
          </ListItem>

          {menuItems.map((item, index) => (
            <Tooltip
              title={item.text}
              key={index}
              placement="right"
              disableHoverListener={open}
            >
              <ListItem
                onClick={() => handleMenuClick(item.link)}
                sx={{
                  justifyContent: open ? "initial" : "center",
                  padding: open ? "8px 16px" : "8px 0",
                  cursor: "pointer",
                  backgroundColor: isItemSelected(item.link)
                    ? colors.gray_3
                    : "transparent",
                  color: isItemSelected(item.link) ? colors.white : colors.gray,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    justifyContent: "center",
                    color: isItemSelected(item.link)
                      ? colors.white
                      : colors.gray,
                    padding: open ? "0 8px" : "0",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                {open && (
                  <ListItemText
                    primary={item.text}
                    sx={{
                      color: isItemSelected(item.link)
                        ? colors.white
                        : colors.gray,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  />
                )}
              </ListItem>
            </Tooltip>
          ))}

          <Tooltip
            title={t("create_conversation")}
            placement="right"
            disableHoverListener={open}
          >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                onClick={handleCreateConversation}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: open ? "8px 16px" : "0px",
                  margin: open ? "16px" : "5px",
                  width: open ? "auto" : "38px",
                  height: open ? "auto" : "48px",
                  borderRadius: open ? "8px" : "50%",
                  backgroundColor: colors.gray_3,
                  color: colors.white,
                  minWidth: "48px",
                  fontSize: fontSize.xsmall,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {!open && (
                  <AddIcon sx={{ color: colors.white, fontSize: "20px" }} />
                )}
                {open && (
                  <>
                    <AddIcon sx={{ color: colors.white, fontSize: "24px" }} />
                    {t("create_conversation")}
                  </>
                )}
              </Button>
            </Box>
          </Tooltip>
        </List>
      </Box>

      <Box>
        <Tooltip
          title={t("settings")}
          placement="right"
          disableHoverListener={open}
        >
          <ListItem
            onClick={() => handleMenuClick("/settings")}
            sx={{
              justifyContent: open ? "initial" : "center",
              padding: open ? "8px 16px" : "8px 0",
              cursor: "pointer",
              backgroundColor: isItemSelected("/settings")
                ? colors.gray_3
                : "transparent",
              color: isItemSelected("/settings") ? colors.white : colors.gray,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                justifyContent: "center",
                color: isItemSelected("/settings") ? colors.white : colors.gray,
                padding: open ? "0 8px" : "0",
              }}
            >
              <SettingsIcon />
            </ListItemIcon>
            {open && (
              <ListItemText
                primary={t("settings")}
                sx={{
                  color: isItemSelected("/settings")
                    ? colors.white
                    : colors.gray,
                }}
              />
            )}
          </ListItem>
        </Tooltip>
      </Box>
    </Drawer>
  );
};

export default Navbar;
