import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Button,
  Typography,
  CircularProgress,
  TextField,
} from "@mui/material";
import ReactGA from "react-ga4";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsersByOrganization } from "../../../redux/actions/userAction";
import { useTranslation } from "react-i18next";

const ShareConversationModal = ({
  open,
  onClose,
  onShare,
  organizationName,
}) => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const dispatch = useDispatch();
  const actual_user = useSelector((state) => state.auth.user.user);
  const { users, loading } = useSelector((state) => state.user);
  const [selectedUsers, setSelectedUsers] = useState([]); // Store user objects
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (open) {
      dispatch(fetchUsersByOrganization(organizationName));
    }
  }, [open, organizationName, dispatch]);

  const handleToggle = (user) => {
    setSelectedUsers((prevSelected) =>
      prevSelected.some((u) => u.id === user.id)
        ? prevSelected.filter((u) => u.id !== user.id)
        : [...prevSelected, user]
    );
  };

  const handleShare = async () => {
    setIsLoading(true);
    const selectedUsersIds = selectedUsers.map((user) => user.id);
    await onShare(selectedUsersIds); // Pass the selected user objects

    // Google Analytics event for sharing conversation
    ReactGA.event({
      category: "Share Modal",
      action: "Conversation Shared",
      label: `Shared with ${selectedUsers.length} user(s)`,
      value: selectedUsers.length, // Optionally track the number of users selected
    });

    onClose();
    setIsLoading(false);
  };

  // Filter users based on search term
  const filteredUsers = users.filter(
    (user) =>
      user.id !== actual_user.id && // Exclude the actual user
      (user.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const handleSearchChange = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);

    // Google Analytics event for search
    ReactGA.event({
      category: "Share Modal",
      action: "Search User",
      label: searchValue,
    });
  };


  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
        dir={currentLang === "ar" ? "rtl" : "ltr"}
      >
        <Typography variant="h6" gutterBottom>
          {t("share_conversation_full")}
        </Typography>

        {/* Search Bar */}
        <TextField
          fullWidth
          variant="outlined"
          placeholder={t("search_user_placeholder")}
          value={searchTerm}
          onChange={handleSearchChange}
          sx={{ mb: 2 }}
        />

        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <List sx={{ maxHeight: 300, overflowY: "auto" }}>
            {filteredUsers.map((user) => (
              <ListItem
                key={user.id}
                disablePadding
                sx={{
                  flexDirection: currentLang === "ar" ? "row-reverse" : "row", // Reverse row direction for Arabic
                }}
              >
                <ListItemAvatar>
                  <Avatar>
                    {user.first_name ? user.first_name[0] : ""}
                    {user.last_name ? user.last_name[0] : ""}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={`${user.first_name} ${user.last_name}`}
                  secondary={user.email}
                  sx={{
                    textAlign: currentLang === "ar" ? "right" : "left", // Align text based on language
                  }}
                />
                <Checkbox
                  edge={currentLang === "ar" ? "start" : "end"} // Align checkbox based on language
                  sx={{
                    marginLeft: currentLang === "ar" ? "auto" : 0,
                    marginRight: currentLang !== "ar" ? "auto" : 0,
                  }}
                  checked={selectedUsers.some((u) => u.id === user.id)}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevents bubbling to parent ListItem
                    handleToggle(user);
                  }}
                />
              </ListItem>
            ))}
          </List>
        )}

        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleShare}
            disabled={selectedUsers.length === 0 || loading || isLoading} // Disable button when loading
            startIcon={isLoading ? <CircularProgress size={20} /> : null} // Show CircularProgress when loading
          >
            {isLoading ? t("loading") : t("share")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ShareConversationModal;
