import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, TextField, Typography, Container, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  clearMessageError,
  forgotPassword,
} from "../../redux/actions/authAction";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import CustomButton from "../../components/CustomButton";
import { colors } from "../../utils/colors";

const ForgotPasswordScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, message } = useSelector((state) => state.auth);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);

  const handleForgotPassword = () => {
    // Validate email
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    setEmailError(!isValidEmail);

    if (isValidEmail) {
      dispatch(forgotPassword(email));
    }
  };

  useEffect(() => {
    // Clear message and error state when this component mounts
    dispatch(clearMessageError());
  }, [dispatch]);

  return (
    <Grid
      container
      sx={{
        height: "90vh",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.bg_color,
      }}
    >
      <Paper
        elevation={4}
        sx={{ borderRadius: "20px", width: "80%", height: "80vh" }}
      >
        <Grid container sx={{ height: "100%" }}>
          {/* Left Column */}
          <Grid
            item
            xs={12}
            md={5}
            sx={{
              backgroundColor: "black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "160px",
                height: "160px",
                backgroundColor: "white",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Logo style={{ width: "140px", height: "140px" }} />
            </div>
          </Grid>

          {/* Right Column */}
          <Grid
            item
            xs={12}
            md={7}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Container maxWidth="xs">
              <Typography variant="h4" sx={{ mb: 4 }}>
                Réinitialiser le mot de passe
              </Typography>

              {error && (
                <Typography color="error" variant="body1">
                  {error}
                </Typography>
              )}

              {message && (
                <Typography color="success.main" variant="body1">
                  {message}
                </Typography>
              )}

              <TextField
                required
                fullWidth
                label="Email"
                margin="normal"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={emailError}
                helperText={
                  emailError ? "Veuillez entrer une adresse e-mail valide." : ""
                }
              />

              <CustomButton
                text="Envoyer le lien de réinitialisation"
                outlined={false}
                loading={loading}
                onClick={handleForgotPassword}
                sx={{ mt: 4, width: "100%" }}
              />

              <Typography
                variant="body2"
                sx={{ textAlign: "center", cursor: "pointer", mt: 2 }}
                onClick={() => navigate("/login")}
              >
                Retour à la connexion
              </Typography>
            </Container>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default ForgotPasswordScreen;
