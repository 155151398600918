export const MESSAGE_STATUS = {
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};

export const ROLE = {
  USER: "user",
  ASSISTANT: "assistant",
};

export const MAX_FILES_UPLOAD = 5;

export const DISCLAIMER_URL = "";

export const TextDirection = {
  RightToLeft: 'rtl',
  LeftToRight: 'ltr',
}

export const languages = {
  ar: {
    _language: "العربية",
    languageCode: "ar",  // Replace LanguageCode.ar
    textDirection: "RightToLeft",  // Replace TextDirection.RightToLeft
    flagUrl: "https://hatscripts.github.io/circle-flags/flags/ma.svg",
    slogan: "نوفر لكم إجابات موثوقة من القانون المغربي.",
    disclaimerText:
      "هذا التصريح لأغراض إعلامية فقط ولا يشكل نصيحة قانونية مهنية.",
    conversationText: "المحادثة",
    inputText: "اكتب سؤالك هنا",
    progressText: "عرض التقدم",
    providedQuestionText: "السؤال المستلم",
    subRequestText: "تم إنشاء طلب فرعي",
    exportText: "استخراج",
    editText: "تعديل",
    saveText: "حفظ",
    cancelText: "إلغاء",
    downloadText: "تحميل",
    yourDocumentsText: "ستظهر مستنداتك هنا",
    newConversationText: "محادثة جديدة",
    logoutText: "تسجيل الخروج",
  },
  en: {
    _language: "English",
    languageCode: "en",  // Replace LanguageCode.en
    textDirection: "LeftToRight",  // Replace TextDirection.LeftToRight
    flagUrl: "https://hatscripts.github.io/circle-flags/flags/us.svg",
    slogan:
      "We provide you with reliable answers from Moroccan regulations.",
    disclaimerText:
      "This statement is for informational purposes only and does not constitute professional legal advice.",
    conversationText: "Conversation",
    inputText: "Type your question here",
    progressText: "View progress",
    providedQuestionText: "Question received",
    subRequestText: "Sub-request generated",
    exportText: "Export",
    editText: "Edit",
    saveText: "Save",
    cancelText: "Cancel",
    downloadText: "Download",
    yourDocumentsText: "Your documents will be displayed here",
    newConversationText: "New conversation",
    logoutText: "Log out",
  },
  fr: {
    _language: "Français",
    languageCode: "fr",  // Replace LanguageCode.fr
    textDirection: "LeftToRight",  // Replace TextDirection.LeftToRight
    flagUrl: "https://hatscripts.github.io/circle-flags/flags/fr.svg",
    slogan:
      "Nous vous fournissons des réponses fiables issues des réglementations marocaines.",
    disclaimerText:
      "Cette déclaration est uniquement à des fins d'information et ne constitue pas un conseil juridique professionnel.",
    conversationText: "Conversation",
    inputText: "Tapez votre question ici",
    progressText: "Visualiser l'avancement",
    providedQuestionText: "Question reçue",
    subRequestText: "Sous Requête Générée",
    exportText: "Exporter",
    editText: "Editer",
    saveText: "Sauvegarder",
    cancelText: "Annuler",
    downloadText: "Télécharger",
    yourDocumentsText: "Vos documents s'afficheront ici",
    newConversationText: "Nouvelle conversation",
    logoutText: "Se déconnecter",
  },
};


// export const faq_upload_question = "Welcome to the upload screen ";
// export const faq_upload_answer =
//   "The upload process utilizes our machine learning algorithm to deconstruct documents into manageable building blocks before uploading them to a graph database. This procedure is complex. Feel free to drag and drop a document to see it uploading in real time.";

// export const faq_explore_question = "Welcome to the explore screen  ";
// export const faq_explore_answer =
//   "Explore is the process throughout which a user can explore a dataroom with ease. Each request can take up to a few seconds to run. We offer a range of filters on the left hand side to filter the results, as well as a preview screen to be able to visualize the results in real time.";

// export const faq_chat_question = "Welcome to our novel conversation agent, DAN";
// export const faq_chat_answer =
//   "Every query to Dan involves a comprehensive search that includes both breadth and depth exploration, along with follow-up questions to the tool and document, making the process quite intricate and time-consuming. We have perfected Dan's workload to ensure each request takes the right amount of time to give a high quality response.";
